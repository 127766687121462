import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Cookies from 'universal-cookie'
import hamburger from '../../assets/images/hamburger.svg'
import { useStore } from '../../mobx-store/context'
import ResourceModal from '../../pages/Profile/components/ResourceAccessModal/resourceModal'
import Logo from '../common/Logo/logo'
import {
  Hamburger,
  LogOut,
  Menu,
  MenuItem,
  SubMenuItem,
  Right,
  Submenu,
  Wrap,
} from './topLine.style'
import NotificationCenterModal from './NotificationCenterModal'
import UserItem from './User'
import BellIconWithBadge from './Bell'
import { api } from '../../api'

const cookies = new Cookies()


const TopLine = observer(({ adminPanel = false }) => {
  const store = useStore()
  const { pathname } = useLocation()
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [visible, setVisible] = useState(false)
  const [showNotifCenter, setShowNotifCenter] = useState(false)
  const [notifCount, setNotifCount] = useState(0)
  const history = useHistory()

  const menu = adminPanel
    ? [
      {
        key: 4,
        title: 'Settings',
        link: '/dashboard-settings',
      },
      {
        key: 5,
        title: 'Metrics',
        link: '/metrics',
      },
      {
        key: 6,
        title: 'Management',
        link: '/dashboard-landing',
      }
    ]
    : [

      {
        key: 0,
        title: 'ComplianceSource',
        link: '/tracking',
      },
      {
        key: 2,
        title: 'ProposedSource',
        link: '/proposed-source',
      },
      {
        key: 1,
        title: 'ReSource',
        link: '/resource/table/guides',
      },
      {
        key: 3,
        title: 'ReportSource',
        link: '/report-source',
      },
      {
        key: 4,
        title: 'LicenseSource',
        link: '/license-source',
      },
    ]


  const isHome =
    pathname === '/home' ||
    pathname === '/about' ||
    pathname === '/home-compliance' ||
    pathname === '/home-resource' ||
    pathname === '/home-report-source' ||
    pathname === '/home-license-source' ||
    pathname === '/home-proposed-source' ||
    pathname === '/secuity-and-privacy' ||
    pathname.split('/', 3).slice(0, 2).join('/') === '/resource-guides-landing'

  const serviceName = pathname.split('/')[1]

  const getNewNotifications = () => {
    api('/notifications/unread_notifications_count/', 'GET').then((res) => {
      setNotifCount(res.count)
    })
  }

  const onClick = (item) => {
    setShowMobileMenu(false)
    if (
      item.key === 0 && (!store.company[0].have_access_to_tracking ||
        !store.user?.[0]?.have_access_to_tracking)
    ) {
      return history.push('/no-compliance-source-subscription/')
    }

    if (
      item.key === 1 &&
      (!store.company[0].have_access_to_resource ||
        !store.user?.[0]?.have_access_to_resource)
    ) {
      return history.push('/no-resource-subscription/')
    }
    // if (
    //   item.key === 1 &&
    //   store.user[0].member_type !== 'admin' &&
    //   !store.user[0].have_access_to_resource
    // ) {
    //   return history.push('/edit-profile')
    // }

    if (
      item.key === 3 &&
      (!store.company[0].have_access_to_report_source ||
        !store.user?.[0]?.have_access_to_report_source)
    ) {
      return history.push('/no-report-source-subscription/')
    }
    if (
      item.key === 4 &&
      (!store.company[0].have_access_to_license_source ||
        !store.user?.[0]?.have_access_to_license_source)
    ) {
      return history.push('/no-license-source-subscription/')
    }
    if (
      item.key === 2 &&
      (!store.company[0].have_access_to_proposed_source ||
        !store.user?.[0]?.have_access_to_proposed_source)
    ) {
      return history.push('/no-proposed-source-subscription/')
    }





    // store.setSingleValue('currentLegislative', null)
    window.open(item.link, '_blank')
  }

  useEffect(() => {
    if (store.user.length <= 0 && !isHome) store.getUser()
    if (cookies.get('pbm_token') && !store.resourceTariffInfo)
      store.getResourceTariffInfo()
    getNewNotifications()
  }, [])


  return (
    <Wrap adminPanel={adminPanel} serviceName={serviceName}>
      {showNotifCenter && (
        <NotificationCenterModal onClose={() => setShowNotifCenter(false)} getNewNotifications={getNewNotifications} />
      )}
      {visible && (
        <ResourceModal
          type={(!store.company[0].have_access_to_tracking ||
            (!store.user?.[0]?.have_access_to_tracking &&
              store.user?.[0]?.member_type === 'admin')) ? 'tracking' : 'resource'}
          to={(!store.company[0].have_access_to_tracking ||
            (!store.user?.[0]?.have_access_to_tracking &&
              store.user?.[0]?.member_type === 'admin')) ? '/topicsAndJur' : '/resource/options'}
          onClose={() => setVisible(false)}
        />
      )}
      <Logo style={{ height: isHome ? 55 : 45, width: 'auto' }} />
      <Right active={showMobileMenu}>
        {cookies.get('pbm_token') && (
          <Menu>
            {menu.map((item) => (
              <MenuItem
                as={'div'}
                onClick={() => onClick(item)}
                key={item.key}
                style={{ paddingRight: '1em', height: 60, }}>
                {item.title} {item.submenu && store.user?.[0]?.have_access_to_tracking && <span>▼</span>}
                {item.submenu && store.user?.[0]?.have_access_to_tracking && (
                  <Submenu>
                    {item.submenu.map((subItem) => (
                      <SubMenuItem
                        as={'div'}
                        key={subItem.key}
                        onClick={(e) => {
                          setShowMobileMenu(false)
                          e.stopPropagation()
                          window.open(subItem.link, '_blank')
                        }
                        }
                        style={{
                          padding: '19px 1em',
                          border: '1px solid #e5e5e5',
                          borderRadius: 4,
                          boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.2)',
                          marginLeft: 0,
                          height: 30,
                        }}
                      >
                        {subItem.title}
                      </SubMenuItem>
                    ))}
                  </Submenu>
                )}
              </MenuItem>
            ))}
          </Menu>
        )}
        {cookies.get('pbm_token') && !adminPanel && <BellIconWithBadge messageCount={notifCount} onClick={() => setShowNotifCenter(true)} />}

        <UserItem
          isHome={isHome}
          cookies={cookies}
          store={store}
          closeMobileMenu={() => setShowMobileMenu(false)}
        />

        {store.user[0] && (
          <LogOut
            to={'/login'}
            onClick={() => {
              cookies.remove('pbm_token')
              store.reset()
            }}
          >
            Log out
          </LogOut>
        )}
      </Right>
      <Hamburger
        src={hamburger}
        alt={'menu-icon'}
        onClick={() => setShowMobileMenu(!showMobileMenu)}
        active={showMobileMenu}
      />
    </Wrap>
  )
})

export default TopLine
