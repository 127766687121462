import React, { useEffect } from 'react'
import Benefits from './Benefits'
import Footer from './Footer'
import Header from './Header'
import { Wrap } from './styles'
import macbookReport from '../../../assets/images/MacBook Pro_Report.png'
import macbookProposed from '../../../assets/images/MacBook Pro_Proposed.png'
import reportBenefitsImg from '../../../assets/images/Benefits4.svg'
import proposedBenefitsImg from '../../../assets/images/proposed-benefits.svg'
import licenseBenefitsImg from '../../../assets/images/license_benefits.svg'

const reportContent = {
  service: 'report-source',
  title: 'ReportSource',
  titleColor: 'var(--orange)',
  bgColor: 'var(--orange)',
  description: ' Don\'t miss or be surprised by another reporting deadline. ReportSource identifies reports and corresponding deadlines for the PBM industry, with attachments/links to report forms, instructions, and guidance.',
  leptopImage: macbookReport,
  benefitsImg: reportBenefitsImg,
  benefitsTitle: 'Never miss a report & be on track with ReportSource',
  benefitsDescription: 'Assign tasks to get information and documentation needed to complete reports, collaborate with team members regarding the report, and track the reporting to submission, including email reminders of due dates. The ReportSource dashboard provides everything in one place.',
  benefits: [
    {
      key: 0,
      title: 'Automatically identifies and adds required reporting and deadlines.',
    },
    {
      key: 1,
      title: 'The forms, instructions, and other guidance for each report are provided.',
    },
    {
      key: 2,
      title: 'Email alerts when a new report is added into ReportSource.',
    },
    {
      key: 3,
      title: 'Allows for report to be closed and archived once report is submitted, with ongoing access to the reports and internal collaboration.'
    },
  ],

}

const licenseContent = {
  service: 'license-source',
  title: 'LicenseSource',
  titleColor: 'var(--violet)',
  bgColor: 'var(--violet)',
  description: ' Don\'t miss or be surprised by another reporting deadline. LicenseSource identifies reports and corresponding deadlines for the PBM industry, with attachments/links to report forms, instructions, and guidance.',
  leptopImage: macbookReport,
  benefitsImg: licenseBenefitsImg,
  benefitsTitle: 'Identify & manage licenses required for PBMs',
  benefitsDescription: 'LicenseSource is a tool which provides detailed statutory and regulatory information to assist with:',
  DescriptionBullets: [
    {
      key: 0,
      title: 'Determining the applicability of licenses required for PBMs across all 50 states',
    },
    {
      key: 1,
      title: 'Applying for and renewing licenses',
    },
    {
      key: 2,
      title: 'Organizing information related to currently held licenses, including deadlines, notices of change, and supporting documentation.',
    },
  ],
  benefits: [
    {
      key: 0,
      title: 'Provides definitions, lines of business, and penalties for comprehensive review to determine license applicability',
    },
    {
      key: 1,
      title: 'Access to links for current forms, instructions, and other guidance',
    },
    {
      key: 2,
      title: 'List of all items required for license application or renewal with ability to assign tasks for each item',
    },
    {
      key: 3,
      title: 'Notifications when new licenses are added or requirements change'
    },
    {
      key: 4,
      title: 'Maintains record of all relevant documents and information for each license held',
    },
  ],

}

const proposedContent = {
  service: 'proposed-source',
  title: 'ProposedSource Is the best tool for tracking proposed PBM Legislation',
  titleColor: 'var(--text)',
  bgColor: 'var(--grafit-grey)',
  description: 'PBMSource now offers the ability to track the status of proposed legislation. With the latest enhancements, ProposedSource is included with your ComplianceSource subscription at no extra charge, or is able to be purchased separately!',
  leptopImage: macbookProposed,
  benefitsImg: proposedBenefitsImg,
  benefitsTitle: 'Stay up to date on the latest proposed bills & regulations',
  benefitsDescription: '',
  benefits: [
    {
      key: 0,
      title: 'Stay up to date with emerging laws that may impact your organization.',
    },
    {
      key: 1,
      title: 'Receive email alerts when new updates are available.',
    },
    {
      key: 2,
      title: 'Access a compliance dashboard with everything in one place.',
    },
  ],
}

const HomeService = ({ service }) => {

  const content = service === 'report'
    ? reportContent
    : service === 'license' ?
      licenseContent
      : proposedContent
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <Wrap>
      <Header content={content} />
      <Benefits content={content} />
      <Footer />
    </Wrap>
  )
}

export default HomeService
