import Modal from "../../../../components/common/ModalNew"
import { Title, Text } from "./style"
import Button from "../../../../components/common/Button/button"
import { PrevBtn } from "../../CreateCompany/pages/FirstPage/Form/style"

const DeactivationModal = ({ company, onClose, companyName, onSubmit, usersNumber, isInactive }) => {
  const btnColor = isInactive ? 'var(--blue)' : 'var(--red)'
  return (
    <Modal styleWrap={{
      borderRadius: 8,
      padding: 30,
      display: 'flex',
      flexDirection: 'column',
      width: '30%',
    }}
      onClose={onClose}
    >
      <Title> {isInactive ? 'Activate' : 'Deactivate'} {companyName}?</Title>
      <Text>This will {isInactive ? 'activate' : 'deactivate'} all {isInactive ? 'company' : usersNumber} users, are you sure you want to {isInactive ? 'activate' : 'deactivate'} this company?</Text>
      <div style={{ marginTop: 40, width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'center' }}>
        <PrevBtn style={{ marginTop: 7, textDecoration: 'none' }} onClick={() => onClose()}>GoBack</PrevBtn>
        <Button
          text={isInactive ? 'Activate' : 'Deactivate'}
          onClick={(e) => {
            e.stopPropagation()
            onSubmit()
          }}
          style={{
            padding: '10px 10px',
            marginRight: 0,
            marginLeft: '.5em',
            backgroundColor: btnColor,
          }}
        />
      </div>
    </Modal>
  )
}


export default DeactivationModal