import React, { useEffect, useState } from 'react'
import { WrapEditor, Wrap, Toolbar, ToolButton } from '../Details/style'
import { useStore } from '../../../../mobx-store/context'
import {
  Editor,
  EditorState,
  RichUtils,
  convertFromHTML,
  ContentState,
} from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import boldIcon from '../../../../assets/images/bold.svg'
import italicIcon from '../../../../assets/images/italic.svg'
import underlineIcon from '../../../../assets/images/underline.svg'
import '../Details/DraftStyleDefault.css'
import 'draft-js/dist/Draft.css'

const styleMap = {
  'BOLD': {
    fontFamily: 'var(--bold)',
  },
}

const TextEditor = ({ materialType, updateDetails, placeholderText = '', setIsEmpty, initialText = '', edit = false }) => {
  // const store = useStore()
  // const params = useParams()
  // const [data, setData] = useState(null)
  const [active, setActive] = useState({
    BOLD: false,
    ITALIC: false,
    UNDERLINE: false,
  })

  const blocksFromHTML = convertFromHTML(initialText);
  const contentState = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
  );

  const [editorState, setEditorState] = useState(
    EditorState.createWithContent(contentState))
  // const location = useLocation()

  const checkIsOnlySpaces = () => {
    const content = editorState.getCurrentContent()
    const isEditorEmpty = !content.hasText()
    const currentPlainText = content.getPlainText()
    const lengthOfTrimmedContent = currentPlainText.trim().length
    const isContainOnlySpaces = !isEditorEmpty && !lengthOfTrimmedContent

    return isContainOnlySpaces
  }


  const isEmpty = !editorState.getCurrentContent().hasText() || checkIsOnlySpaces()

  // const ID = params.id || store.currentLegislative.id

  const placeholder =
    placeholderText ||
    `Type details for ${materialType === 'admin_actions'
      ? 'Administrative Actions'
      : materialType === 'market_conduct_exams_held'
        ? 'Market Conduct Exams'
        : materialType === 'misc_held'
          ? 'Misc' : 'Notice of Change'
    }`

  // const onSubmit = () => {

  //   if (isEmpty || checkIsOnlySpaces()) return null
  //   const value = stateToHTML(editorState.getCurrentContent())
  //   api(`/legislative-details/${ID}/`, {
  //     details_html: value,
  //   }, 'PUT').then(data => {
  //     if (!data.errors) {
  //       setData(data)
  //     }
  //   })
  // }

  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)

    if (newState) {
      this.onChange(newState)
      return 'handled'
    }

    return 'not-handled'
  }

  const transformText = (e, type) => {

    setActive({ ...active, [type]: !active[type] })

    // onMouseDown and e.preventDefault because editor losses focus if you use onClick
    e.preventDefault()

    let nextState = RichUtils.toggleInlineStyle(editorState, type)

    setEditorState(nextState)

  }


  // useEffect(() => {
  //   if (data?.details_html) {
  //     const blocksFromHTML = convertFromHTML(data.details_html)
  //     const state = ContentState.createFromBlockArray(
  //       blocksFromHTML.contentBlocks,
  //       blocksFromHTML.entityMap,
  //     )
  //     setEditorState(EditorState.createWithContent(state))
  //   } else {
  //     setEditorState(EditorState.createEmpty())
  //   }

  // }, [data])

  useEffect(() => {
    if (isEmpty) setActive({
      BOLD: false,
      ITALIC: false,
      UNDERLINE: false,
    })
  }, [isEmpty])

  useEffect(() => {
    if (isEmpty) setIsEmpty(true)
    else setIsEmpty(false)

  }, [isEmpty])


  return (
    <>
      <Wrap>
        <WrapEditor>
          <Toolbar>
            <ToolButton onMouseDown={(e) => transformText(e, 'BOLD')}
              active={active['BOLD']}>
              <img src={boldIcon} alt="bold-icon" />
            </ToolButton>
            <ToolButton onMouseDown={(e) => transformText(e, 'ITALIC')}
              active={active['ITALIC']}>
              <img src={italicIcon} alt="italic-icon" />
            </ToolButton>
            <ToolButton onMouseDown={(e) => transformText(e, 'UNDERLINE')}
              active={active['UNDERLINE']}>
              <img src={underlineIcon} alt="underline-icon" />
            </ToolButton>
          </Toolbar>
          <Editor
            editorState={editorState}
            onChange={editorState => {
              setEditorState(editorState)
              updateDetails(stateToHTML(editorState.getCurrentContent()))
            }}
            handleKeyCommand={handleKeyCommand}
            placeholder={placeholder}
            customStyleMap={{
              ...editorState.getCurrentInlineStyle(),
              ...styleMap,
            }}
          />
        </WrapEditor>
        {/* <Button
          text={data?.details_html ? 'Edit Details' : 'Post Details'}
          style={{ fontSize: 12 }}
          onClick={onSubmit}
          disabled={isEmpty || checkIsOnlySpaces()}
        /> */}
      </Wrap>
      {/* {data?.details_html && <Item data={data}/>} */}

    </>
  )
}

export default TextEditor
