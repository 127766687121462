import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Row } from '../HeldModal/style'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'
import { Controller } from 'react-hook-form'
import CalendarCustom from '../../../../components/common/Calendar'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import deleteFileIcon from '../../../../assets/images/delete-orange.svg'
import { useGA4React } from 'ga-4-react'
import moment from 'moment'
import MyEditor from '../../../AdminPanel/ReportEdit/ReportPage/CheckboxGroup/Editor'
import TextEditor from './TextEditor'

const HeldAdminItemAddModal = ({
  onClose,
  id,
  itemId,
  heldType,
  setTrigger,
  trigger,
  edit,
  initialText = '',
  initialFiles = [],
  removeFile,
}) => {
  const store = useStore()
  const { control, errors, setValue, handleSubmit } = useForm()
  const [temporaryFiles, setTemporaryFiles] = useState(edit ? initialFiles : [])
  const [details, setDetails] = useState(edit ? initialText : '')
  const [isEmpty, setIsEmpty] = useState(false)
  const ga4React = useGA4React()

  // const isEmpty = details.trim() === '' || details === '<p><br></p>' 

  const postFile = (files, id) => {

    ga4React?.gtag('event', 'file_upload')

    let formData = new FormData()

    formData.append('file', files[0])

    api(`/license_company/${id}/${heldType}/${id}/files/`, formData, 'POST', false, true).then((data) => {
      if (!data.errors) {
      } else {

      }
    })
  }

  const addHeldDocs = async (temporaryFiles, id, setTrigger, trigger) => {
    if (temporaryFiles.length > 0) {
      const filtered = temporaryFiles.filter((item) => !item.id)
      const postFilePromises = filtered.map(item => postFile(item, id));
      await Promise.all(postFilePromises);
      setTrigger(!trigger);
    } else { setTrigger(!trigger); }
  };

  const onSubmit = (values) => {
    const url = edit
      ? `/license_company/${id}/${heldType}/${itemId}/`
      : `/license_company/${id}/${heldType}/`
    api(url, {
      description: details,
    }, edit ? 'PATCH' : 'POST').then((data) => {
      if (!data.errors) {
        addHeldDocs(temporaryFiles, data.id, setTrigger, trigger)
        onClose()
      }
    })
  }

  const addFile = (e) => {
    setTemporaryFiles([...temporaryFiles, [...e.target.files]])
  }


  const deleteFile = (name, item) => {
    const filtered = temporaryFiles.filter((item) => item[0] ? item[0].name !== name : item.file_name !== name)
    setTemporaryFiles(filtered)
    item.id && removeFile(itemId, item.id, false)
  }

  const title = heldType === 'admin_actions'
    ? 'Admin Actions'
    : heldType === 'market_conduct_exams_held' ?
      'Market Conduct Exams'
      : heldType === 'misc_held'
        ? 'Misc'
        : 'Notice of Change'

  return (
    <ModalWrap onClose={onClose}>
      <Label>{title}</Label>
      <TextEditor
        title={'Summary'}
        updateDetails={setDetails}
        placeholderText={`Type details for ${title}`}
        initialText={edit ? initialText : ''}
        edit={edit}
        setIsEmpty={setIsEmpty}
      />
      <Input
        type={'file'}
        name={'file'}
        label={"Attach Documents."}
        control={control}
        styleRow={{ marginTop: 10, marginBottom: 10 }}
        handleChange={(e) => addFile(e)}
        multiple
        smallLabel
      />
      {temporaryFiles.map((item, index) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonOrange text={item[0] ? item[0].name : item.file_name} key={index} />
          <img
            src={deleteFileIcon}
            alt={'delete-icon'}
            onClick={() => deleteFile(item[0] ? item[0].name : item.file_name, item)}
            style={{ marginLeft: 5, cursor: 'pointer' }}
          />
        </div>
      ))}

      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          disabled={isEmpty}
          text={'Add Details'}
          style={{ marginLeft: 'auto' }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </ModalWrap>
  )
}

export default HeldAdminItemAddModal
