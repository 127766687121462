import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import { api } from '../../api'
import placeholderImage from '../../assets/images/legislatives-palceholder.svg'
import reportPlacegolderImage from '../../assets/images/report-empty.svg'
import FullScreenLoader from '../../components/common/FullScreenLoader'
import Tabs from '../../components/common/Tabs/tabs'
import Container from '../../components/containers/container'
import {
  checkForRegisterPassed,
  redirectToUnfilledPage,
} from '../../helpers/helpers'
import { useStore } from '../../mobx-store/context'
import Auth from './Auth/auth'
import CreateNewComplianceItem from './CreateNew'
import Left from './Left/left'
import Right from './Right/right'
import { Title, TitleContainer } from './Left/left.style'
import Reporting from './Reporting'
import ReportCalendar from './ReportCalendar'
import Disclaimer from '../../components/common/Disclaimer'
import TCModal from './Modal/tcModal'
import { NotChoosen } from './Right/right.style'
import { Top, Wrap, Back } from './tracking.style'
import CustomPromptModal from '../../components/common/CustomPrompt/CustomPromptModal'
import { tabs, liteTabs, reportTabs, reportLiteTabs, lsPendingTabs, lsRenewalsTabs, lsHeldTabs, lsTasksTabs, lsCalendarTabs } from './static'
import { TrialBanner } from './TrialBanner/index'
import ChangePasswordModal from '../Auth/ChangePassowordModal'
import LsHighOrderTabs from './LsTabs'
import { Spin } from 'antd'
import { is, set } from 'immutable'

const Tracking = observer(({ serviceName }) => {
  const store = useStore()
  const history = useHistory()
  const params = useParams()
  const [active, setActive] = useState(serviceName === 'license' ? 'all' : 'main')
  const [currentLegislativeLoading, setCurrentLegislativeLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [visible, setVisible] = useState(false)
  const [editing, setEditing] = useState(false)
  const [touched, setTouched] = useState(false)
  const [visibleConfModal, setVisibleConfModal] = useState(false);
  const [expiredPassword, setExpiredPassword] = useState(false);
  const [showDisclaimer, setShowDisclaimer] = useState({ show: false, fadeOut: false });
  const [detailsState, setDetailsState] = useState(null)
  const [rightSideLoading, setRightSideLoading] = useState(false)
  const [lsChapter, setLsChapter] = useState(
    params.tabName === 'all'
      || params.tabName === 'need'
      || params.tabName === 'not_assessd'
      || params.tabName === 'monitor'
      || params.tabName === 'na'
      ? 'pending_assessments'
      : params.tabName === 'held'
        ? 'held'
        : params.tabName === 'renewals_reporting'
          || params.tabName === 'apcd'
          || params.tabName === 'closed' ?
          'renewals_reporting'
          : params.tabName === 'assigned'
            || params.tabName === 'pending_tasks' ? 'tasks' : 'pending_assessments'
  )

  const isReportSource = serviceName === 'report'
  const isComplianceSource = serviceName === 'compliance'
  const isLicenseSource = serviceName === 'license'
  const isRenewalsReporting =
    params.tabName === 'renewals_reporting'
    || params.tabName === 'assigned'
    || params.tabName === 'closed'
    || params.tabName === 'all_renewals'

  const urls = {
    default: isReportSource ? 'page_size=20&status=actual' : 'page_size=20&actual=actual',
    closed: 'page_size=20&status=closed',
    myTasks: 'page_size=20&status=mytasks',
    monitor: 'page_size=20&status=monitor',
    apcd: 'page_size=20&status=apcd',
    held: 'page_size=20&status=held',
    all: isLicenseSource ? 'page_size=20&tab_name=all' : 'page_size=20',
  }

  const ending = {
    main: `get_page_for=legislatives&actual=actual`,
    myTasks: `get_page_for=tasks`,
    closed: `get_page_for=legislatives&status=closed`,
    monitor: `get_page_for=legislatives&status=monitor`,
    apcd: '`get_page_for=legislatives&status=apcd',
    all: `get_page_for=legislatives&all=all`,
  }

  const loading = false
  const auth = false
  const company = store.company?.[0]
  const user = store.user?.[0]

  const trackingMode = isReportSource ? company?.report_source_mode || localStorage.getItem('reportInLs') === 'true' : company?.compliance_source_mode
  const showModeBlocks = isReportSource
    ? localStorage.getItem('reportInLs') === 'true' ? true :
      trackingMode === 'report_source' ? true : false
    : trackingMode === 'compliance_source' ? true : false

  let search = new URL(document.location).searchParams
  let complianceItem = search.get('compliance_item')
  let report = search.get('report')
  let license_item = search.get('license_item')
  const prevItem = isReportSource ? report : complianceItem

  const serviceMember = isReportSource
    ? user?.report_source_role
    : isLicenseSource
      ? user?.license_source_role
      : user?.member_type

  const isMyTasks =
    active === 'myTasks' ||
    (!isLicenseSource && serviceMember === 'member' && active !== 'closed')

  const onLoad = (data) => {
    let status;
    if (active === 'all') {
      status = {};
    } else if (active === 'all_renewals') {
      status = { tab_name: 'all_renewals', source_type: 'renewals_reporting' };
    }
    else {
      switch (data.status) {
        case 'closed':
          status = { status: 'closed' };
          break;
        case 'monitor':
          status = { status: 'monitor' };
          break;
        case 'mytasks':
          status = { status: 'mytasks' };
          break;
        default:
          status = { actual: 'actual' };
          break;
      }
    }

    store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
      page: data.page || currentPage || 1,
      page_size: 20,
      ...status,

    }, isReportSource, isLicenseSource)
  }

  const onError = () => {
    store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
      page: 1,
      page_size: 20,
    }, isReportSource, isLicenseSource)
  }

  const getData = () => {
    setCurrentStatus();

    const lastSearchName = isReportSource
      ? 'remember_my_last_search_report_source'
      : isLicenseSource
        ? 'remember_my_last_search_license_source'
        : 'remember_my_last_search_compliance';

    if (checkForRegisterPassed(store.company[0], isReportSource, isLicenseSource) && store.user.length > 0) {
      if (params.id || complianceItem || report || license_item) {
        getCurrentLegislative(params.id || complianceItem || report || license_item, onLoad, onError, false, isReportSource, isLicenseSource);
      } else {
        if (isMyTasks) {
          handleMyTasks(lastSearchName, isReportSource);
        } else {
          handleOtherCases(lastSearchName, isReportSource, isLicenseSource);
        }
      }
    }
  };

  const handleMyTasks = (lastSearchName, isReportSource) => {
    if (store.user[0][lastSearchName][active] || store.firstTimeRememberSearch) {
      api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
        updateSearchableObjectTasks(data, isReportSource);
        loadTasksOrLegislatives(isReportSource);
        store.setSingleValue('firstTimeRememberSearch', false);
      });
    } else {
      store.setSingleValue('searchableObjectTasks', { ...store.searchableObjectTasks, tab_name: active });
      loadTasksOrLegislatives(isReportSource);
    }
  };

  const handleOtherCases = (lastSearchName, isReportSource, isLicenseSource) => {
    if (active === 'calendar' || params.tabName === 'calendar') {
      handleCalendarCase(lastSearchName, isReportSource, isLicenseSource);
    } else {
      if (store.user[0][lastSearchName][active] || store.firstTimeRememberSearch) {
        api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
          updateSearchableObject(data, isReportSource, isLicenseSource);
          loadLegislatives(isReportSource, isLicenseSource);
          store.setSingleValue('firstTimeRememberSearch', false);
        });
      } else {
        store.setSingleValue('searchableObject', { ...store.searchableObject, tab_name: active });
        loadLegislatives(isReportSource, isLicenseSource);
      }
    }
  };


  const handleCalendarCase = (lastSearchName, isReportSource, isLicenseSource) => {
    const dataName = isReportSource ? 'report_data' : isLicenseSource ? 'license_data' : 'compliance_data';
    if (store.user[0][lastSearchName].calendar || store.firstTimeRememberSearch) {
      api(`/users/${store?.user[0]?.id}/last_search_data/`, {}, 'GET').then((data) => {
        store.setSingleValue('searchableObject', { ...data[dataName].calendar, tab_name: active, ...store?.calendarDateRange });
        store.loadLegislatives({
          page: 1,
          page_size: checkForTrial() ? 10 : 20,
        }, isReportSource, isLicenseSource);
        store.setSingleValue('firstTimeRememberSearch', false);
      });
    } else {
      store.setSingleValue('searchableObject', { ...store?.searchableObject, tab_name: active, ...store?.calendarDateRange });
      store.loadLegislatives({
        page: 1,
        page_size: checkForTrial() ? 10 : 20,
      }, isReportSource, isLicenseSource);
    }
  };

  const updateSearchableObjectTasks = (data, isReportSource) => {
    isReportSource
      ? store.setSingleValue('searchableObjectTasks', { ...data.report_data[active], tab_name: active })
      : isLicenseSource
        ? store.setSingleValue('searchableObjectTasks', { ...data.license_data[active], tab_name: active })
        : store.setSingleValue('searchableObjectTasks', { ...data.compliance_data[active], tab_name: active });
  };

  const loadTasksOrLegislatives = (isReportSource) => {
    store[active === 'closed' ? 'loadLegislatives' : 'loadTasks']({
      page: 1,
      page_size: checkForTrial() ? 10 : 20,
    }, isReportSource, isLicenseSource);
  };

  const updateSearchableObject = (data, isReportSource, isLicenseSource) => {
    isReportSource
      ? store.setSingleValue('searchableObject', { ...data.report_data[active], tab_name: active })
      : isLicenseSource
        ? store.setSingleValue('searchableObject', { ...data.license_data[active], tab_name: active })
        : store.setSingleValue('searchableObject', { ...data.compliance_data[active], tab_name: active });
  };

  const loadLegislatives = (isReportSource, isLicenseSource) => {
    store.loadLegislatives({
      page: 1,
      page_size: checkForTrial() ? 10 : 20,
    }, isReportSource, isLicenseSource);
  };


  const updateDetails = () => {
    if (detailsState === '<p><br></p>' || detailsState === store.detailsInitialValue) { return }
    const url = isReportSource
      ? `/report_company/${store?.currentLegislative?.id}/details/`
      : isLicenseSource ? `/license_company/${store?.currentLegislative?.id}/details/`
        : `/legislative-details/${store?.currentLegislative?.id}/`;
    api(url, { details_html: detailsState, }, 'PUT').then((data) => {
      setDetailsState(null)
    })
  }

  const getCurrentLegislative = (id, onLoad, onError, isClicked, isReportSource, isLicenseSource, needToReload = false, pageLoad = true) => {
    // const ordering = store?.searchableObject?.ordering ? `&ordering=${store.searchableObject.ordering}` : '';
    const url = isLicenseSource || localStorage.getItem('reportInLs') === 'true' ?
      active === 'renewals_reporting'
        || active === 'apcd'
        || active === 'closed'
        || active === 'assigned'
        || active === 'all_renewals'
        ?
        localStorage.getItem('reportInLs') === 'true' ? `/report_company/${id}/?tab_name=${active}&source_type=renewals_reporting&${store.searchableString || ''}`
          : `/renewals_reporting/${id}/?source_type=renewals_reporting&${store.searchableString || ''}`
        : `/license_company/${id}/?${urls[active === 'closed'
          ? 'closed'
          : active === 'all'
            ? 'all'
            : active === 'apcd'
              ? 'apcd'
              : active === 'held' ?
                'held'
                : 'default']}&${store.searchableString || ''}`
      : isReportSource && localStorage.getItem('reportInLs') !== 'true'
        ? `/report_company/${id}/?${urls[active === 'closed'
          ? 'closed'
          : active === 'all'
            ? 'all'
            : active === 'apcd'
              ? 'apcd'
              : active === 'myTasks'
                ? 'myTasks'
                : 'default']}&${store.searchableString}`
        : `/legislative/${id}/?${isClicked
          ? urls[active === 'closed'
            ? 'closed'
            : active === 'all'
              ? 'all'
              : active === 'monitor'
                ? 'monitor'
                : 'default']
          : ending[active]}&${store.searchableString || ''}`
    needToReload && setRightSideLoading(true)
    api(url, {}, 'GET').then((data) => {
      if (!data.errors) {
        if (data.status === 'closed' && active !== 'all' && !isLicenseSource) {
          history.push(`${isReportSource ? '/report-source/' : '/tracking/'}closed/?${isReportSource ? 'report' : 'compliance_item'}=${data.id}`)
          setActive('closed')
        }
        onLoad && onLoad(data)
        store.setCurrentLegislative(data)

        pageLoad ? setCurrentPage(data.page || currentPage || 1) : setCurrentPage(currentPage)
        setDetailsState(null)
      } else {
        onError && onError()
        store.setCurrentLegislative(null)
        setDetailsState(null)
      }
      setCurrentLegislativeLoading(false)
      setVisible(false)
      setDetailsState(null)
    }).finally(() => {
      needToReload && setRightSideLoading(false)
    })
  }

  const checkForTrial = () =>
    store.company.length > 0 && !isReportSource && store.company[0].payment_status === 'trial'

  const archived = active === 'closed'
  const all = active === 'all'
  const monitor = active === 'monitor'
  const apcd = active === 'apcd'
  const calendar = active === 'calendar'

  const setCurrentStatus = () => {
    if (archived) {
      store.setSingleValue('legislativesStatus', getArchivedStatus());
    } else {
      setNonArchivedStatus();
    }
  };

  const getArchivedStatus = () => {
    if (all) return 'all';
    if (monitor) return 'monitor';
    if (apcd) return 'apcd';
    if (calendar) return 'calendar';
    return 'closed';
  };

  const setNonArchivedStatus = () => {
    const status = store.searchableObject?.status;

    if (status === 'closed' || status === 'monitor') {
      store.setSingleValue('legislativesStatus', status);
    }

    if (calendar) {
      store.setSingleValue('legislativesStatus', 'calendar');
    }
  };

  const onCreateNew = () => {
    if (touched) { setVisibleConfModal(true) }
    else {
      history.push(`/${isReportSource
        ? 'report-source'
        : 'tracking'}/createNew`)
      setVisible(true)
    }
  }

  const onConfirmConfModal = () => {
    setEditing(false)
    setVisible(false)
    setTouched(false)
    setVisibleConfModal(false)
  }

  const onTabClick = (key) => {
    if (key === active) return null
    if (touched) { setVisibleConfModal(true) } else {
      onTabChange(key)
      setActive(key)
      setVisible(false)
    }
  }


  const statsStatus = active === 'apcd' ? 'status=apcd' : 'status=actual'
  const statsStatusLS = active === 'apcd' ? 'status=apcd' : 'status=renewals_reporting'

  const onTabChange = (key) => {
    history.push(`${isReportSource ? '/report-source/' : isLicenseSource ? '/license-source/' : '/tracking/'}${key}`);

    store.setSingleValue('currentLegislative', null);
    store.setSingleValue('legislatives', null);
    store.setSingleValue('tasks', null);

    const status = getStatusByKey(key);
    store.setSingleValue('legislativesStatus', status);
  };

  const getStatusByKey = (key) => {
    switch (key) {
      case 'closed':
        return 'closed';
      case 'all':
        return 'all';
      case 'all_renewals':
        return 'all_renewals';
      case 'monitor':
        return 'monitor';
      case 'apcd':
        return 'apcd';
      case 'calendar':
        return 'all';
      case 'held':
        return 'held';
      case 'main'
        : return 'actual';
      default:
        return 'actual';
    }
  };


  let content;

  if (active === 'reporting-task') {
    content = <Reporting isReportSource={isReportSource} reportingTask={true} />
  } else if (active === 'reporting-item') {
    content = <Reporting isReportSource={isReportSource} reportingTask={false} />
  }
  else if (active === 'calendar') {
    content = <ReportCalendar
      setActive={setActive}
      setLsChapter={setLsChapter}
      getData={getData}
      isLicenseSource={isLicenseSource}
      isReportSource={isReportSource}
      getCurrentLegislative={
        (id) => getCurrentLegislative(id, null, null, true, isReportSource, isLicenseSource)} />
  } else {
    content =
      <Wrap>
        <>
          {store.user.length > 0 && (
            <Left
              auth={auth}
              active={active}
              setActive={(key) => setActive(key)}
              changeTab={(key) => setActive(key)}
              getCurrentLegislative={
                (id) => getCurrentLegislative(id, null, null, true, isReportSource, isLicenseSource, true)
              }
              currentLegislativeLoading={currentLegislativeLoading}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              complianceItem={isReportSource ? report : isLicenseSource ? license_item : complianceItem}
              onCreateNew={onCreateNew}
              setTouched={(value) => setTouched(value)}
              setEditing={(value) => setEditing(value)}
              setVisible={(value) => setVisible(value)}
              touched={touched} editing
              isReportSource={isReportSource}
              isLicenseSource={isLicenseSource}
              isMyTasks={isMyTasks}
              archived={active === 'closed'}
              all={active === 'all'}
              monitor={active === 'monitor'}
              apcd={active === 'apcd'}
              getData={getData}
            />
          )}

          {auth ? (
            <Auth />
          ) : visible ? (
            <CreateNewComplianceItem
              onClose={() => {
                setTouched(false)
                setVisible(false)
                setEditing(false)
              }}
              touched={(value) => setTouched(value)}
              editing={editing}
              isMyTasks={isMyTasks}
              activeTab={active}
              isReportSource={isReportSource}
              getCurrentLegislative={(id) =>
                getCurrentLegislative(id, (data) => {
                  isMyTasks ?
                    store.loadTasks({
                      page: data.page,
                      page_size: 20,
                    }, isReportSource)
                    : store.loadLegislatives({
                      page: 1,
                      page_size: 20,
                    }, isReportSource)
                }, null, true, isReportSource, isLicenseSource)
              }
            />
          ) : store.currentLegislative && !rightSideLoading ? (
            <Right
              detailsState={detailsState}
              setDetailsState={setDetailsState}
              updateDetails={updateDetails}
              data={toJS(store.currentLegislative)}
              getCurrentLegislative={getCurrentLegislative}
              showModeBlocks={showModeBlocks}
              trackingMode={trackingMode}
              currentPage={currentPage}
              isMyTasks={isMyTasks}
              complianceItem={isReportSource ? report : isLicenseSource ? license_item : complianceItem}
              activeTab={active}
              isReportSource={isReportSource || localStorage.getItem('reportInLs') === 'true'}
              isLicenseSource={isLicenseSource && localStorage.getItem('reportInLs') !== 'true'}
              onEdit={() => {
                setVisible(true)
                setEditing(true)
              }}
            />
          ) : rightSideLoading ?
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%' // или нужная высота
            }}>
              <Spin />
            </div>
            :
            (
              <NotChoosen>
                <img src={isReportSource ? reportPlacegolderImage : placeholderImage} alt='placeholder' />
                Select a {isReportSource ? 'report entry' : isLicenseSource ? 'license entry' : 'compliance entry'} to begin
              </NotChoosen>
            )}
        </>
      </Wrap>
  }

  useEffect(() => {
    store.getUser()
  }, [active])

  useEffect(() => {
   localStorage.getItem('noResetForReportInLs') !== 'true' && localStorage.setItem('reportInLs', false)
  //  localStorage.getItem('reportInLs') === 'false' && localStorage.setItem('noResetForReportInLs', false)
  }, [])

  useEffect(() => {
    isReportSource
      ? document.title = 'PBMSource - ReportSource'
      : isLicenseSource ?
        document.title = 'PBMSource - LicenseSource'
        : document.title = 'PBMSource - ComplianceSource'
  }, []);

  useEffect(() => {
    if (store.company.length && checkForRegisterPassed(store.company[0], isReportSource, isLicenseSource)) {
      store.loadSelect('companyOptions')
    } else {
      redirectToUnfilledPage(history, store)
    }
  }, [])

  useEffect(() => {
    const user = store.user?.[0]
    if (isReportSource && user?.report_source_role === 'member') setActive('myTasks')
    if (isLicenseSource && !isReportSource && user?.license_source_role === 'member') {
      if (params.tabName === 'assigned') { setActive('assigned') }
      else if (params.tabName === 'pending_tasks') { setActive('pending_tasks') }
      else {
        store.setSingleValue('legislativesStatus', 'assigned');
        setActive('assigned')
      }
      setLsChapter('tasks')
    }
    if (params.tabName === 'all_renewals') {
      setActive('all_renewals')
      setLsChapter('renewals_reporting')
    }
    if (!isReportSource && !isLicenseSource && user?.member_type === 'member') setActive('myTasks')
    if (user && !user?.have_access_to_tracking && !user?.have_access_to_report_source && !user.have_access_to_license_source)
      history.push('/edit-profile')
  }, [store.user])

  useEffect(() => {
    active !== 'calendar' && getData()
  }, [active, store.user])



  useEffect(() => {
    if (isMyTasks) setActive('myTasks')
  }, [isMyTasks])

  useEffect(() => {
    if (params.tabName && active !== params.tabName) {
      setActive(params.tabName)
      if (params.tabName === 'main') {
        store.setSingleValue('legislativesStatus', 'all')
        return
      }
      if (params.tabName === 'held') {
        store.setSingleValue('legislativesStatus', 'held')
        return
      }
      if (params.tabName === 'not_assessed') {
        store.setSingleValue('legislativesStatus', 'not_assessed')
        return
      }
      if (params.tabName === 'need') {
        store.setSingleValue('legislativesStatus', 'need')
        return
      }
      if (params.tabName === 'closed') {
        store.setSingleValue('legislativesStatus', 'closed')
      } if (params.tabName === 'all') {
        store.setSingleValue('legislativesStatus', 'all')
        return
      } if (params.tabName === 'all_renewals') {
        store.setSingleValue('legislativesStatus', 'all_renewals')
        return
      }
      if (params.tabName === 'monitor') {
        store.setSingleValue('legislativesStatus', 'monitor')
        return
      }
      if (params.tabName === 'apcd') {
        store.setSingleValue('legislativesStatus', 'apcd')
        return
      }
      if (params.tabName === 'renewals_reporting') {
        store.setSingleValue('legislativesStatus', 'renewals_reporting')
        return
      }
      if (params.tabName === 'assigned') {
        store.setSingleValue('legislativesStatus', 'assigned')
        return
      }
      if (params.tabName === 'pending_tasks') {
        store.setSingleValue('legislativesStatus', 'pending_tasks')
        return
      }
      if (params.tabName === 'myTasks') {
        setActive('myTasks')
        return
      }
      else {
        store.setSingleValue('legislativesStatus', 'actual')
      }
    }
    if (params.tabName && !params.tabName === 'reporting' && (
      isReportSource
        ? !reportTabs.find((item) => item.key === params.tabName)
        : !tabs.find((item) => item.key === params.tabName))) {
      setActive('main')
      isReportSource
        ? history.push('/report-source/main')
        : history.push('/tracking/main')
    }
  }, [params.tabName])


  useEffect(() => {
    if ((isReportSource || (isLicenseSource && active === 'apcd')) && (active === 'main' || active === 'apcd')) {
      api(`/report_company/stats/?${statsStatus}`, {}, 'GET').then((data) => {
        store.setSingleValue('reportStats', data)
      })
    }
    if ((isLicenseSource && active !== 'apcd') && (active === 'renewals_reporting')) {
      api(`/renewals_reporting/stats/?${statsStatusLS}`, {}, 'GET').then((data) => {
        store.setSingleValue('reportStats', data)
      })
    }
  }, [active])

  useEffect(() => {
    store.setSingleValue('currentItemLinkedReports', null)
    store.setSingleValue('currentItemLinkedRenewals', null)
    if (isReportSource && report) {
      api(`/report_company/${report}/prior_reports/`, {}, 'GET').then((data) => {
        store.setSingleValue('currentItemLinkedReports', data)
      })
    } else if (isLicenseSource && !isRenewalsReporting && license_item) {
      api(`/license_company/${license_item}/determination/${store?.currentDetermination?.id}/prior_reports/`, {}, 'GET').then((data) => {
        store.setSingleValue('currentItemLinkedReports', data)
      })
      api(`/license_company/${license_item}/determination/${store?.currentDetermination?.id}/prior_renewals/`, {}, 'GET').then((data) => {
        store.setSingleValue('currentItemLinkedRenewals', data)
      })
    } else if (isLicenseSource && isRenewalsReporting && license_item) {
      if (localStorage.getItem('reportInLs') === 'true') {
        api(`/report_company/${license_item}/prior_renewals/`, {}, 'GET').then((data) => {
          store.setSingleValue('currentItemLinkedRenewals', data)
        })
        api(`/report_company/${license_item}/prior_reports/`, {}, 'GET').then((data) => {
          store.setSingleValue('currentItemLinkedReports', data)
        })
      } else {
        api(`/renewals_reporting/${license_item}/prior_renewals/`, {}, 'GET').then((data) => {
          store.setSingleValue('currentItemLinkedRenewals', data)
          store.setSingleValue('currentItemLinkedReports', null)
        })
      }
    }
  }, [report, license_item,])

  useEffect(() => {
    setExpiredPassword(localStorage.getItem('is_time_to_change_password') === 'true')
  }, [])

  useEffect(() => {
    store.loadSelect('currentMemberOptions')
  }, [])

  useEffect(() => {
    const show = sessionStorage.getItem('showDisclaimer') === 'true';
    setShowDisclaimer({ show: show, fadeOut: !show });
  }, []);

  const handleDisclaimerClose = () => {
    setShowDisclaimer(prev => ({ ...prev, fadeOut: true }));
    setTimeout(() => {
      setShowDisclaimer({ show: false, fadeOut: false });
      sessionStorage.setItem('showDisclaimer', 'false');
    }, 500);
  }

  useEffect(() => {
    store.setSingleValue('currentLegislative', null)
  }, [])

  useEffect(() => {
    (isReportSource || isLicenseSource) &&
      api('/reporting_entities/', {}, 'GET').then((data) => {
        store.setSingleValue('reportingEntities', data)
      })
  }, [])

  useEffect(() => {
    (isReportSource || isLicenseSource) &&
      api('/report_types/', {}, 'GET').then((data) => {
        store.setSingleValue('reportTypes', data)
      })
  }, [])

  useEffect(() => {
    const statuses = ['trial_is_over', 'error', 'canceled']
    if (statuses.includes(company.payment_status)) {
      history.push(
        `/company_error/${company.payment_status === 'trial_is_over'
          ? company.payment_status
          : 'payment_' + company.payment_status
        }`
      )
    }
  }, [company])

  useEffect(() => {
    detailsState && updateDetails()
  }, [complianceItem, report, license_item, params.id])

  if (loading || !store.user[0]) return <FullScreenLoader />

  return (
    store.company.length > 0 &&
    checkForRegisterPassed(store.company[0], isReportSource, isLicenseSource) &&
    !loading &&
    (user.have_access_to_tracking || user.have_access_to_report_source || user.have_access_to_license_source) && (
      <>
        <Container wide>
          {expiredPassword && <ChangePasswordModal onClose={() => setExpiredPassword(false)} expiredPassword />}
          <Top fullWidth>
            <Back style={{ marginTop: '7.75em' }} onClick={() => history.goBack()}>Go Back</Back>
            <TitleContainer>
              <Title>
                {isReportSource
                  ? trackingMode === 'report_source_lite'
                    ? 'ReportSource Lite'
                    : 'ReportSource'
                  : isLicenseSource
                    ? 'LicenseSource'
                    : trackingMode === 'compliance_source_ultra_lite'
                      ? 'ComplianceSource Ultra Lite'
                      : trackingMode === 'compliance_source_lite'
                        ? 'ComplianceSource Lite'
                        : 'ComplianceSource'}
              </Title>
              {checkForTrial() && <TrialBanner />}
            </TitleContainer>
            {isLicenseSource && user.license_source_role !== 'member' &&
              <LsHighOrderTabs
                defaultState={lsChapter}
                onTabChange={(key) => {
                  if (key === 'pending_assessments') {
                    setActive('all')
                    onTabClick('all')
                    setLsChapter('pending_assessments')
                  } else if (key === 'held') {
                    setActive('held')
                    onTabClick('held')
                    setLsChapter('held')
                  } else if (key === 'renewals_reporting') {
                    setActive('renewals_reporting')
                    onTabClick('renewals_reporting')
                    setLsChapter('renewals_reporting')
                  } else if (key === 'tasks') {
                    setActive('assigned')
                    onTabClick('assigned')
                    setLsChapter('tasks')
                  } else if (key === 'calendar') {
                    setActive('calendar')
                    onTabClick('calendar')
                    setLsChapter('calendar')
                  }
                }}
                tabs={[
                  { title: 'Pending Assessments', key: 'pending_assessments' },
                  { title: 'Held', key: 'held' },
                  { title: 'Renewals & Reporting', key: 'renewals_reporting' },
                  { title: 'Tasks', key: 'tasks' },
                  { title: 'Calendar', key: 'сalendar' },
                ]}
              />
            }
            <Tabs
              tabs={isReportSource
                ? showModeBlocks
                  ? reportTabs
                  : reportLiteTabs
                : isLicenseSource ?
                  lsChapter === 'pending_assessments'
                    ? lsPendingTabs
                    : lsChapter === 'renewals_reporting'
                      ? lsRenewalsTabs
                      : lsChapter === 'held'
                        ? lsHeldTabs
                        : lsChapter === 'tasks' ? lsTasksTabs : lsCalendarTabs
                  : showModeBlocks
                    ? tabs
                    : liteTabs}
              miltiTabs={isLicenseSource}
              isReportSource={isReportSource}
              isLicenseSource={isLicenseSource}
              active={active}
              onClick={(key) => onTabClick(key)}
            />
          </Top>
          {content}
          {
            !user.is_accepted_terms_and_conditions &&
            <TCModal />
          }
          {
            user.should_see_pbm_updates &&
            <TCModal type='announcement' />
          }
          <Prompt when={touched} message={'Are you sure you want to leave before saving?'} />
          <div id='custom-prompt'></div>
          {visibleConfModal &&
            <CustomPromptModal
              onConfirm={onConfirmConfModal}
              onCancel={() => setVisibleConfModal(false)} />}
        </Container>
        {showDisclaimer.show && <Disclaimer onClose={handleDisclaimerClose} fadeOut={showDisclaimer.fadeOut} />}
      </>
    )
  )
})

export default Tracking
