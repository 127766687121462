import styled from 'styled-components'
import { Title as GlobalTitle } from '../style'

export const Wrap = styled('div')`
position: relative;
display: flex;
flex-direction: column;
align-items: center;
height: max-content;
padding: 32px;
border: 1px solid #E4E7EC;
box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.1), 0px 4px 6px 1px rgba(16, 24, 40, 0.05);
border-radius: 16px;
  @media (max-width: 1300px) {
    height: auto;
  }
`

export const TitleContainer = styled('div')`
 display:flex;
 flex-direction: column;
 align-items: center
`

export const Title = styled(GlobalTitle)`
  font-size: 28px;
  @media (max-width: 1200px) {
    font-size: 22px;
  }
`

export const ListDescription = styled('div')`
  font-family: var(--semiBold);
  font-size: 20px;
  color: var(--grey900);
  line-height: 24px;
  margin-top: 20px;
  @media (max-width: 1200px) {
    font-size: 16px;
  }
`
export const Description = styled('div')`
text-align:center; 
margin-top: 5px;
margin-bottom: 30px;
font-family: var(--regular);
font-size: 16px;
color: var(--grey500);
@media (max-width: 1200px) {
  font-size: 16px;
}
`

export const ListItem = styled('div')`
  display: flex;
  align-items: flex-start;
  padding-left: 15px;
  font-family: var(--regular);
  font-size: 16px;
  color: var(--text);
  margin-bottom: 25px;
  border-top: 1px solid ${(p) => (p.line ? '#CACFD1' : 'transparent')};
  padding-top: ${(p) => (p.line ? '15px' : '0')};
  @media (max-width: 1200px) {
    font-size: 14px;
  }

  img {
    height: 17px;
    margin-right: 23px;

  }
`
export const BlockWrap = styled('div')`
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 80px 58px;
  border-radius:${(props) => props.number === 0
    ? '12px 0px 0px 0px'
    : props.number === 1
      ? '0px 12px 0px 0px'
      : props.number === 2
        ? '0px 0px 0px 12px'
        : props.number === 3
          ? '0px 0px 12px 0px'
          : '0px 0px 0px 0px'};
`
export const BlockContent = styled('div')`
  width: 370px;
  display: flex;
  flex-direction: column;
`
export const ImageBlock = styled('div')`

  margin-right: 15px;
  margin-top: 10px;
  opacity: ${(props) => (props.disabled && '0.6')};
`
export const BlockTitle = styled('div')`
  color: ${(props) => (`var(--${props.color})`)};
  font-size: 28px;
  font-family: var(--medium);
  opacity: ${(props) => (props.disabled && '0.6')};
`
export const FillerTitle = styled('div')`
  color: var(--text);
  font-size: 32px;
  font-family: var(--bold);
  opacity: 0.4;};
`


export const BlockSubtitle = styled('div')`
color: var(--text, #455154);
font-size: 20px;
font-family: var(--Inter-bold);
font-weight: 600;
line-height: 30px;;
`
export const BlockDescription = styled('div')`
  color: var(--text);
  line-height: 1.3;
  height: 48px;
  font-size: 16px;
  font-family: var(--medium);
  opacity: ${(props) => (props.disabled && '0.6')};
`
export const BlockSignUpButton = styled('div')`
  color: #225194;
  line-height: 1.3;
  font-size: 16px;
  font-family: var(--medium);
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`