import React, { useState, useRef, useEffect } from 'react';
import './styles.css'
import { StyledInputTag, TagsList, Tag, TagButton, InputWrapper, StyledInput, Title } from './style'
import { api } from '../../../../../api';
import { useStore } from '../../../../../mobx-store/context';


function ImpactedClients({ id, isReportSource = false, isLicenseSource = false, isRenewalsReporting = false, disabled = false, getDataFromHistory = false, initalLicenseId }) {
  const [tags, setTags] = useState([]);
  const tagInputRef = useRef(null);
  const store = useStore();

  const removeTag = (i) => {
    const newTags = [...tags];
    newTags.splice(i, 1);
    setTags(newTags);
    manageTags('POST', newTags);
  };

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if ((e.key === 'Enter' || e.key === ',') && val) {
      const tagToAdd = val.trim().replace(',', '');
      if (tags.find(tag => tag.toLowerCase() === tagToAdd.toLowerCase())) {
        return;
      }
      const newTags = [...tags, tagToAdd];
      setTags(newTags);
      manageTags('POST', newTags);
      tagInputRef.current.value = '';
    } else if (e.key === 'Backspace' && !val) {
      const newTags = [...tags];
      newTags.splice(tags.length - 1, 1);
      setTags(newTags);
      manageTags('POST', newTags);
    }
  };

  const manageTags = (action, newTags = null) => {
    const url = isReportSource
      ? `/report_company/${id}/impacted_clients/`
      : isLicenseSource ?
        isRenewalsReporting
          ? `/renewals_reporting/${id}/impacted_clients/`
          : `/license_company/${id}/impacted_clients/`
        : `/legislative/${id}/impacted_clients/`;
    if (action === 'GET') {
      getDataFromHistory ?
        setTags(store?.assessmentImpactedClients)
        : api(url, {}, 'GET').then((data) => {
          setTags(data.impacted_clients);
        });
    } else if (action === 'POST' && newTags) {
      api(url, { impacted_clients: newTags }, 'POST');
    }
  };

  useEffect(() => {
    manageTags('GET');
  }, [id])

  return (
    <>
      <Title style={{ marginTop: 15, marginBottom: 10 }}>Impacted Clients</Title>
      <StyledInputTag>
        <TagsList>
          {tags.map((tag, i) => (
            <Tag key={tag}>
              {tag}
              {!disabled && <TagButton onClick={() => removeTag(i)}>X</TagButton>}
            </Tag>
          ))}
          <InputWrapper>
            <StyledInput
              disabled={disabled}
              type="text"
              onKeyDown={inputKeyDown}
              ref={tagInputRef}
              placeholder={tags.length > 0 ? '' : disabled ? 'There are no impacted clients' : 'Add clients that are impacted (optional), separate by commas'} />
          </InputWrapper>
        </TagsList>
      </StyledInputTag>
    </>
  );
}

export default ImpactedClients;
