import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { api } from '../../../../api'
import ButtonOrange from '../../../../components/common/ButtonOrange'
import { checkRole } from '../../../../helpers/helpers'
import useDidMountEffect from '../../../../hooks/useEffectNotOnPageLoad'
import useModal from '../../../../hooks/useModal'
import { useStore } from '../../../../mobx-store/context'
import { Label } from '../right.style'
import List from './List'
import Modal from './Modal'
import { Placeholder, Wrap } from './style'

const Tasks = observer(
  ({
    data,
    getCurrentLegislative,
    postFile,
    isMyTasks,
    currentPage,
    loadingFile,
    complianceItem,
    getTasks,
    tasks,
    activeTab,
    setActiveTab,
    isReportSource = false,
    isLicenseSource = false,
    visible,
    handleClose,
    handleOpen,
    assigneNew,
    setAssigneNew,
    getConsiderationData,
    receiveGetReadyData,
    disabled=false,
  }) => {
    const store = useStore()
    const { control, handleSubmit, errors, reset, getValues } = useForm()
    // const { visible, handleClose, handleOpen } = useModal()
    const [shouldComplete, setShouldComplete] = useState(false)
    const [temporaryFiles, setTemporaryFiles] = useState([])
    const [loading, setLoading] = useState(false)
    const [currentTask, setCurrentTask] = useState()

    const params = useParams()
    const history = useHistory()

    const ID = data.id
    const reportId = data?.report?.id

    let search = new URL(document.location).searchParams
    let taskID = search.get('taskID')
    const isRenewalsReporting = params.tabName === 'renewals_reporting' || params.tabName === 'assigned' || params.tabName === 'all_renewals'

    const onComplete = (values) => {
      api(isReportSource ?
        `/report_company/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
        : isLicenseSource ?
          isRenewalsReporting ?
            `/renewals_reporting/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
            : `/license_company/${store.currentLegislative.id}/tasks/${taskID || store.currentLegislative.id}/complete/`
          : `/tasks/${taskID || store.currentLegislative.id}/complete/`,
        { text: values.description },
        'POST'
      ).then((data) => {
        setLoading(false)

        if (!data.errors) {
          temporaryFiles.length > 0 &&
            temporaryFiles.map((item) => {
              postFile(null, taskID, item, true)
            })
          setTemporaryFiles([])
          getCurrentLegislative(ID, null, null, true, isReportSource, isLicenseSource)
          isReportSource || isLicenseSource ? receiveGetReadyData(isLicenseSource, isReportSource, isRenewalsReporting, store?.currentDetermination?.id) : getConsiderationData()
          getTasks()
          store.loadActivity(store.currentLegislative.id, 1, null, isReportSource, isLicenseSource, isRenewalsReporting)
          handleClose()
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          }, isReportSource && localStorage.getItem('reportInLs') !== 'true', isLicenseSource || localStorage.getItem('reportInLs') === 'true')
          const routePath = isReportSource && localStorage.getItem('reportInLs') !== 'true'
            ? '/report-source'
            : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
              ? '/license-source'
              : '/tracking';

          const routeType = isMyTasks
            ? 'myTasks'
            : params.tabName === 'main'
              ? 'main' : params.tabName === 'apcd'
                ? 'apcd'
                : params.tabName === 'all'
                  ? 'all'
                  : params.tabName === 'monitor'
                    ? 'monitor'
                    : params.tabName === 'main'
                      ? 'main' :
                      params.tabName === 'held' ?
                        'held'
                        : params.tabName === 'not_assessed' ?
                          'not_assessed'
                          : params.tabName === 'need' ?
                            'need'
                            : params.tabName === 'na' ?
                              'na'
                              : params.tabName === 'renewals_reporting' ?
                                'renewals_reporting'
                                : params.tabName === 'pending_tasks' ?
                                  'pending_tasks'
                                  : params.tabName === 'assigned' ?
                                    'assigned'
                                    : params.tabName === 'all_renewals' ?
                                      'all_renewals'
                                      : 'closed';
          const queryParam = isReportSource && localStorage.getItem('reportInLs') !== 'true'
            ? 'report'
            : isLicenseSource || localStorage.getItem('reportInLs') === 'true'
              ? 'license_item'
              : 'compliance_item';
          const complianceItemId = complianceItem || ID;
          const route = `${routePath}/${routeType}/?${queryParam}=${complianceItemId} `;
          history.push(route);
        }
        setActiveTab()
        setShouldComplete(false)
      })
    }

    const onSubmit = (values) => {
      setLoading(true)
      if (shouldComplete) return onComplete(values)
      const url =
        isReportSource
          ? taskID && !assigneNew
            ? `/report_company/${store.currentLegislative.id}/tasks/${taskID}/`
            : `/report_company/${store.currentLegislative.id}/tasks/`
          : isLicenseSource
            ? activeTab === 'renewals_reporting'
              || activeTab === 'assigned'
              || activeTab === 'all_renewals' ?
              taskID && !assigneNew
                ? `/renewals_reporting/${store.currentLegislative.id}/tasks/${taskID}/`
                : `/renewals_reporting/${store.currentLegislative.id}/tasks/`
              : taskID && !assigneNew
                ? `/license_company/${store.currentLegislative.id}/tasks/${taskID}/`
                : `/license_company/${store.currentLegislative.id}/tasks/`
            : taskID && !assigneNew
              ? `/tasks/${taskID || store.currentLegislative.id}/`
              : '/tasks/'
      const object = taskID && !assigneNew ? {
        legislative: store.currentLegislative.id,
        ...values,
      }
        : !!store.getReadyTask && !!store.getReadyTask.id ?
          {
            legislative: store.currentLegislative.id,
            type: isReportSource ?
              !!store.getReadyTask && !!store.getReadyTask.id
                ? 'get_ready_task'
                : 'default'
              : isLicenseSource ?
                !!store.getReadyTask && !!store.getReadyTask.id
                  ? 'get_ready_task'
                  : 'default'
                : store.considerationTask || 'default',
            get_ready_checkbox: store.getReadyTask.id,
            ...values,
          }
          : {
            legislative: store.currentLegislative.id,
            type: isReportSource
              ? !!store.getReadyTask
                && !!store.getReadyTask.id
                ? 'get_ready_task'
                : 'default'
              : store.considerationTask || 'default',

            ...values,
          }
      api(
        url,
        object,
        taskID && !assigneNew ? 'PATCH' : 'POST'
      ).then((data) => {
        setLoading(false)
        isReportSource || isLicenseSource ? receiveGetReadyData(isLicenseSource, isReportSource, isRenewalsReporting, store?.currentDetermination?.id) : getConsiderationData()
        isReportSource || isLicenseSource ? store.setSingleValue('getReadyTask', null) : store.setSingleValue('considerationTask', null)
        if (!data.errors) {
          handleClose()
          getCurrentLegislative(ID, null, null, true, isReportSource, isLicenseSource)
          store.loadActivity(ID, 1, null, isReportSource, isLicenseSource, isRenewalsReporting)
          store[isMyTasks ? 'loadTasks' : 'loadLegislatives']({
            page: currentPage,
          }, (isReportSource && localStorage.getItem('reportInLs') !== 'true'), (isLicenseSource || localStorage.getItem('reportInLs') === 'true'))
          getTasks()
          const routePath = isReportSource && localStorage.getItem('reportInLs') !== 'true' ? '/report-source/' : isLicenseSource || localStorage.getItem('reportInLs') === 'true' ? '/license-source/' : '/tracking/';

          const routeType = isMyTasks
            ? 'myTasks'
            : params.tabName === 'main'
              ? 'main'
              : params.tabName === 'apcd'
                ? 'apcd'
                : params.tabName === 'all'
                  ? 'all'
                  : params.tabName === 'monitor'
                    ? 'monitor'
                    : params.tabName === 'closed'
                      ? 'closed'
                      : params.tabName === 'need'
                        ? 'need'
                        : params.tabName === 'held'
                          ? 'held'
                          : params.tabName === 'na'
                            ? 'na'
                            : params.tabName === 'main' ?
                              'main'
                              : params.tabName === 'not_assessed' ?
                                'not_assessed'
                                : params.tabName === 'renewals_reporting' ?
                                  'renewals_reporting'
                                  : params.tabName === 'pending_tasks' ?
                                    'pending_tasks'
                                    : params.tabName === 'assigned' ?
                                      'assigned'
                                      : params.tabName === 'all_renewals' ?
                                        'all_renewals'
                                        : 'all';
          const queryParam = isReportSource && localStorage.getItem('reportInLs') !== 'true' ? 'report' : isLicenseSource || localStorage.getItem('reportInLs') === 'true' ? 'license_item' : 'compliance_item';
          const complianceItemId = ID || params.id;
          const route = `${routePath}${routeType}/?${queryParam}=${complianceItemId}`;
          history.push(route)
          setAssigneNew(false)
        }
      })
    }

    const addFiles = (files) => {
      setTemporaryFiles(files)
      reset({ ...getValues(), file: null })
    }

    useEffect(() => {
      getTasks()
    }, [store.currentLegislative])

    useDidMountEffect(() => {
      if (!visible) {
        reset({ file: null })
      }
    }, [visible])

    return (
      <Wrap name={'tasks-block'}>
        <Label>Tasks</Label>
        {visible && (
          <Modal
            data={currentTask}
            control={control}
            onClose={() => {
              handleClose()
              reset({})
              setShouldComplete(false)
              setAssigneNew(false)
              setTemporaryFiles([])
              setCurrentTask(null)
              store.setSingleValue('getReadyTask', null)
              store.setSingleValue('considerationTask', null)
            }}
            onSubmit={handleSubmit(onSubmit)}
            errors={errors}
            assigneNew={assigneNew}
            complete={shouldComplete}
            postFile={postFile}
            taskID={taskID}
            tasks={tasks}
            complianceItem={complianceItem}
            loadingFile={loadingFile}
            redCircleDate={data?.compliance_date}
            temporaryFiles={temporaryFiles}
            setTemporaryFiles={addFiles}
            loading={loading}
            getTasks={getTasks}
            activeTab={activeTab}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            isRenewalsReporting={isRenewalsReporting}
            getConsiderationData={getConsiderationData}
            receiveGetReadyData={receiveGetReadyData}
          />
        )}
        {!data.assignee && (
          <Placeholder>{isReportSource
            ? 'You must assign a ReportSource Owner before assigning tasks.'
            : isLicenseSource ?
              'You must assign a Assessment  Owner before assigning tasks.'
              : 'You must assign a ComplianceSource Owner before assigning tasks.'
          }
          </Placeholder>
        )}
        {tasks.length > 0 && (
          <List
            currentTask={currentTask}
            setCurrentTask={setCurrentTask}
            tasks={tasks}
            complianceItem={complianceItem}
            isMyTasks={isMyTasks}
            taskID={taskID}
            onModalOpen={(data) => {
              reset(data)
              handleOpen()
            }}
            onComplete={() => {
              setShouldComplete(true)
              setAssigneNew(false)
              handleOpen()
            }}
            data={data}
            getTasks={getTasks}
            isReportSource={isReportSource}
            isLicenseSource={isLicenseSource}
            activeTab={activeTab}
            disabled={disabled ? disabled : store?.currentLegislative?.status === 'held'}
          />
        )}
        {isReportSource
          ? data.assignee &&
          checkRole(
            [store.user?.[0]?.report_source_role],
            ['owner', 'admin', 'report_owner']
          ) &&
          data.status !== 'closed'  && (
            <ButtonOrange
              text={'Assign a Task'}
              onClick={() => {
                reset({
                  assignee: null,
                  due_date: null,
                  description: null,
                })
                setAssigneNew(true)
                handleOpen()
              }}
            />
          )
          :
          isLicenseSource ?
            data.assignee &&
            checkRole(
              [store.user?.[0]?.license_source_role],
              ['owner', 'admin', 'license_owner']
            ) &&
            (data.status !== 'held') && !disabled && (
              <ButtonOrange
                text={'Assign a Task'}
                onClick={() => {
                  reset({
                    assignee: null,
                    due_date: null,
                    description: null,
                  })
                  setAssigneNew(true)
                  handleOpen()
                }}
              />
            )
            : data.assignee &&
            checkRole(
              [store.user?.[0]?.member_type],
              ['owner', 'admin', 'compliance_owner']
            ) &&
            data.status !== 'closed' && (
              <ButtonOrange
                text={'Assign a Task'}
                onClick={() => {
                  reset({
                    assignee: null,
                    due_date: null,
                    description: null,
                  })
                  setAssigneNew(true)
                  handleOpen()
                }}
              />
            )}
      </Wrap>
    )
  }
)

export default Tasks
