import { useForm } from "react-hook-form";
import Input from "../../../../components/common/Input";
import { useStore } from "../../../../mobx-store/context";
import { min } from "moment";
import { useEffect } from "react";
const LicenseSelectFilter = ({ type, activeTab }) => {
  const { control, handleSubmit, reset, } = useForm();
  const store = useStore();

  const onReset = () => {
    reset();
    store.setSingleValue('mainFilterReset', false);
  };

  const renewalsTypes = [
    {
      name: 'Renewals',
      id: 'renewals',
    },
    {
      name: 'Reports',
      id: 'reports',
    },
    {
      name: 'Show All',
      id: 'all',
    },
  ];

  const pendingStatuses = [
    {
      name: 'Show All',
      id: 'all',
    },
    {
      name: 'Not Assessed',
      id: 'not_assessed',
    },
    {
      name: 'Need',
      id: 'need',
    },
    {
      name: 'Monitor',
      id: 'monitor',
    },
  ];


  const onSubmit = (values) => {
    let searchObject = {}
    for (let key in values) {
      values[key]
        ? searchObject[key] = values[key]
        : searchObject[key] = 'all'
    }
    type === 'pending' ?
      store.setSingleValue('searchableObject', {
        ...searchObject,
      })
      : store.setSingleValue('searchableObject', {
        ...searchObject,
        source_type: 'renewals_reporting',
        tab_name: activeTab,
        status: activeTab,
      })
    store.loadLegislatives({ page: 1, page_size: 20 }, false, true)
  };

  useEffect(() => {
    if (store.mainFilterReset === true) {
      onReset();
    }
  }, [store.mainFilterReset]);

  return (
    <>
      {type === 'pending'
        ? <Input
          type={'select'}
          listHeight={170}
          selectAll={false}
          styleRow={{ width: 190, minHeight: 20, height: 30 }}
          style={{ marginBottom: 10, minHeight: 20, height: 30 }}
          name={'status'}
          control={control}
          options={pendingStatuses}
          placeholder={'Showing All'}
          disabled={false}
          handleChange={handleSubmit(onSubmit)}
          selectVars={{ value: 0, name: 1 }}
          smallLabel
          transparent
          getPopupContainer={false}
        />
        : <Input
          type={'select'}
          listHeight={170}
          selectAll={false}
          styleRow={{ width: 190, minHeight: 20, height: 30 }}
          style={{ marginBottom: 10, minHeight: 20, height: 30 }}
          name={'show_items'}
          control={control}
          options={renewalsTypes}
          placeholder={'Showing All'}
          disabled={false}
          handleChange={handleSubmit(onSubmit)}
          selectVars={{ value: 0, name: 1 }}
          smallLabel
          transparent
          getPopupContainer={false}
        />
      }
    </>
  );
};

export default LicenseSelectFilter;