import React, { useState } from 'react'
import Button from '../../../../components/common/Button/button'
import { Label } from '../../../../components/common/Input/input.style'
import ModalWrap from '../../../../components/common/ModalNew/index'
import { Cancel } from '../Tasks/Modal/style'
import Input from '../../../../components/common/Input/index'
import { useForm } from 'react-hook-form'
import { api } from '../../../../api'
import { useStore } from '../../../../mobx-store/context'
import TextEditor from '../HeldAdminItemAddModal/TextEditor'
import { message } from 'antd'

const NoLongerHeldModal = ({
  onClose,
  id,
  currentPage,
}) => {
  const store = useStore()
  const { control, handleSubmit } = useForm()
  const [details, setDetails] = useState('')


  const onSubmit = (values) => {
    if (isEmpty) return
    const url = `/license_company/${id}/remove_license_from_held/`
    api(url, {
      move_to: values.status,
      reason: details,
    }, 'POST').then((data) => {
      if (!data.errors) {
        store.loadLegislatives({
          page: currentPage,
          page_size: 20,
        }, false, true);
        // values.status !== 'not_assessed' && store.setSingleValue('currentLegislative', data)
        onClose()
        message.success('License moved successfully')
      }
    })
  }

  const title = 'This License Is No Longer Held'

  function convertAndFilterArray(arr) {
    return arr
      .filter(subArray => subArray[0] !== 'held')
      .map(subArray => ({
        id: subArray[0],
        name: subArray[1]
      }));
  }


  const isEmpty = !/[a-zA-Z0-9]/.test(details.replace(/<[^>]*>/g, '').replace(/&nbsp;/g, '').trim());

  return (
    <ModalWrap onClose={onClose}>
      <Label style={{ marginBottom: 20 }}>{title}</Label>
      <Input
        label={'Move License'}
        placeholder={'Select an Option'}
        control={control}
        type={'select'}
        options={convertAndFilterArray(store?.license_source_statuses)}
        dropdownStyle={{ zIndex: 100000 }}
        name={'status'}
        required={true}
        smallLabel
      />
      <TextEditor
        title={'Summary'}
        updateDetails={setDetails}
        placeholderText='Add details for why this license is no longer held.'
        edit={false}
        setIsEmpty={() => { }}
        initialText={''}
      />
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 30 }}>
        {<Cancel onClick={onClose}>Cancel</Cancel>}
        <Button
          disabled={isEmpty}
          text={'Move License'}
          style={{ marginLeft: 'auto' }}
          onClick={handleSubmit(onSubmit)}
        />
      </div>
    </ModalWrap>
  )
}

export default NoLongerHeldModal
