import React from 'react';
import ButtonOrange from '../../../../../components/common/ButtonOrange';
import { useState, useEffect } from 'react';
import { api } from '../../../../../api';
import { message } from 'antd';
import ItemBlock from './itemBlock';

const HeldMaterials = ({ liceneCompanyId, materialType, onAddNew, trigger, setTrigger }) => {
  const [data, setData] = useState([]);

  const getHeldMaterials = () => {
    setData([])
    const url = `/license_company/${liceneCompanyId}/${materialType}/`
    api(url, 'GET').then(data => {
      if (data.errors) {
        setData([])
        return message.error('Error while fetching held materials')
      }
      setData(data)
    })
  }

  const deliteFile = (blockId, fileId, update = true) => {
    const url = `/license_company/${liceneCompanyId}/${materialType}/${blockId}/files/${fileId}/`
    api(url, {}, 'DELETE').then(data => {
      if (data.errors) {
        return message.error('Error while deleting file')
      }
      update && getHeldMaterials()
    })
  }


  useEffect(() => {
    getHeldMaterials()
  }, [materialType, liceneCompanyId, trigger])


  return (
    <>
      <ButtonOrange
        text={'Add New'}
        onClick={() => onAddNew()}
        style={{
          fontFamily: 'var(--bold)',
          fontSize: 14,
          textDecoration: 'underline',
          marginTop: 15
        }}
      />
      {data && data.length > 0 && (
        data.map((item, index) => (
          <ItemBlock
            key={data.id}
            item={item}
            id={data.id}
            deliteFile={deliteFile}
            liceneCompanyId={liceneCompanyId}
            materialType={materialType}
            trigger={trigger}
            setTrigger={setTrigger}
          />
        ))
      )}
    </>
  );
};

export default HeldMaterials;