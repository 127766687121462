import { useEffect, useState } from "react";
import { useStore } from "../../../../../../../mobx-store/context";
import { StyledSwitch } from "../../../FirstPage/style";
import { useHistory } from "react-router-dom";
import { SwitchTitle, SwitchText, Row, DetailsWrap, SwitchWrap } from "../../style";
import { useForm } from "react-hook-form";
import Input from "../../../../../../../components/common/Input";
import { Divider, Checkbox, message } from "antd";
import Button from "../../../../../../../components/common/Button/button";
import { PrevBtn } from "../../../FirstPage/Form/style";
import CheckboxGroup from '../../CheckboxGroup'
import { api } from "../../../../../../../api";
import moment from 'moment'
import { set } from "mobx";
import { Table } from 'antd'
import { TableHeaderTitle } from "../../../../../CompaniesTable/style";
import { StyledTable } from "../../../../../EditCompany/Tabs/Users/style";

const UsersTab = ({ handleTabChange }) => {
  const history = useHistory()
  const {
    handleSubmit,
    reset,
    control,
    getValues,
    watch,
    errors,
    clearErrors, } = useForm()
  const store = useStore()
  const [hasAccessToCompliance, setHasAccessToCompliance] = useState(false)
  const [hasAccessToResource, setHasAccessToResource] = useState(false)
  const [hasAccessToReport, setHasAccessToReport] = useState(false)
  const [hasAccessToProposed, setHasAccesstoProposed] = useState(false)
  const [loading, setLoading] = useState(true)
  const [isFormValid, setIsFormValid] = useState(false);
  const [edit, setEdit] = useState(false)
  const [currentUser, setCurentUser] = useState(null)
  const [page, setPage] = useState(1)

  const complianceMembersTypes = [
    { id: 'member', name: 'ComplianceSource Team Member' },
    { id: 'compliance_owner', name: 'ComplianceSource Owner' },
    { id: 'admin', name: 'ComplianceSource Admin' }
  ]
  const resourceMembersTypes = [
    { id: 'member', name: 'ReSource Team Member' },
    { id: 'admin', name: 'ReSource Admin' }
  ]
  const reportMembersTypes = [
    { id: 'member', name: 'ReportSource Team Member' },
    { id: 'report_owner', name: 'ReportSource Owner' },
    { id: 'admin', name: 'ReportSource Admin' }
  ]

  const validateForm = () => {
    const formValues = getValues();
    const isAllFieldsValid = (
      formValues.email &&
      formValues.first_name &&
      formValues.last_name
    );
    setIsFormValid(isAllFieldsValid);
  };


  const onSubmit = (values) => {
    const data = {
      company: localStorage.getItem('cmsCompanyId'),
      email: values.email,
      first_name: values.first_name,
      last_name: values.last_name,
      password: values.password,
      member_type: values.member_type,
      resource_role: values.resource_role,
      report_source_role: values.report_source_role,
      have_access_to_tracking: hasAccessToCompliance,
      have_access_to_proposed_source: hasAccessToProposed,
      have_access_to_resource: hasAccessToResource,
      have_access_to_report_source: hasAccessToReport,
      phone_number: values.phone_number,
    }
    const url = edit ? `/cms/users/${currentUser}/` : `/cms/users/`
    api(url, data, edit ? 'PATCH' : 'POST').then((res) => {
      if (!res.error) {
        getCompanyUsers()
        reset({
          email: '',
          first_name: '',
          last_name: '',
          member_type: 'member',
          resource_role: 'member',
          report_source_role: 'member',
          phone_number: '',
        })
        setHasAccessToCompliance(false)
        setHasAccesstoProposed(false)
        setHasAccessToResource(false)
        setHasAccessToReport(false)
        edit && setEdit(false)
      } else {
        message.error(res.error, 2)
      }
    })
  }

  const onNameClick = (id) => {
    setCurentUser(id)
    setEdit(true)
    const currentUserData = store.cmsCompanyUsers.results.find((user) => user.id === id)
    reset({
      email: currentUserData.email,
      first_name: currentUserData.first_name,
      last_name: currentUserData.last_name,
      member_type: currentUserData.member_type,
      resource_role: currentUserData.resource_role,
      report_source_role: currentUserData.report_source_role,
    })
    setHasAccessToCompliance(currentUserData.have_access_to_tracking)
    setHasAccesstoProposed(currentUserData.have_access_to_proposed_source)
    setHasAccessToResource(currentUserData.have_access_to_resource)
    setHasAccessToReport(currentUserData.have_access_to_report_source)
  }

  const finish = () => {
    localStorage.removeItem('cmsCompanyId')
    history.push('/dashboard-landing/')
  }

  const columns = [
    {
      title: <TableHeaderTitle>Name</TableHeaderTitle>,
      dataIndex: 'full_name',
      key: 'full_name',
      sorter: false,
      render: (text, record) => (
        <div
          style={{
            color: '#007AFF',
            cursor: 'pointer',
            textDecoration: 'underline'
          }}
          onClick={() => onNameClick(record.id)}
        >
          {text}</div>
      ),
    },
    {
      title: <TableHeaderTitle>Email</TableHeaderTitle>,
      dataIndex: 'email',
      key: 'email',
      sorter: false,
    },
    {
      title: <TableHeaderTitle>Products</TableHeaderTitle>,
      dataIndex: 'products',
      key: 'products',
    },
  ]

  const getCompanyUsers = () => {
    setLoading(true)
    api(`/cms/users/?company=${localStorage.getItem('cmsCompanyId')}&page=${page}`, {}, 'GET').then((res) => {
      if (!res.errors)
        store.setSingleValue('cmsCompanyUsers', res)
      setLoading(false)
    })
  }

  useEffect(() => {
    getCompanyUsers()
  }, [page])

  const disabled = getValues('password_confirm') && getValues('password') &&
    getValues('password') !== getValues('password_confirm')

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
        <Input
          smallLabel
          name={'email'}
          label={'Email Address'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Email'}
          handleChange={() => validateForm()}
        />
        <Input
          smallLabel
          validation={{ required: false }}
          name={'phone_number'}
          label={'Phone Number'}
          error={errors && errors.phone_number && 'Enter a valid number'}
          type={'phone'}
          transparent
          control={control}
          handleChange={() => validateForm()}
        />
      </Row >
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          smallLabel
          name={'first_name'}
          label={'First Name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'First name'}
          handleChange={() => validateForm()}
        />
        <Input
          smallLabel
          name={'last_name'}
          label={'Last Name'}
          type={'text'}
          control={control}
          validation={{ required: true }}
          transparent
          placeholder={'Last name'}
          handleChange={() => validateForm()}
        />
      </Row>

      {/* <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Input
          name={'password'}
          type={'password'}
          label={'New Password'}
          control={control}
          error={errors && errors.password && 'Enter valid password'}
          handleChange={(value) => {
            if (
              value &&
              getValues('password_confirm') &&
              value === getValues('password_confirm')
            ) {
              clearErrors(['password_confirm'])
            }
          }}
          validation={{
            required: true,
            validate: (value) => value.length >= 8,
          }}
          showErrorText={false}
          success={getValues('password')?.length >= 8}
          smallLabel
          transparent
        />
        <Input
          name={'password_confirm'}
          type={'password'}
          label={'Confirm New Password'}
          control={control}
          error={
            (disabled || errors.password_confirm) && 'Password doesn\'t match'
          }
          validation={{
            validate: (value) => value === getValues('password'),
            required: true,
          }}
          showErrorText={false}
          success={
            getValues('password') &&
            getValues('password_confirm') &&
            !errors.password_confirm &&
            getValues('password') === getValues('password_confirm')
          }
          smallLabel
          transparent
          handleChange={() => validateForm()}
        />
      </Row> */}
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 25 }}>
        <div style={{ width: '100%' }}>
          <SwitchWrap>
            <StyledSwitch
              disabled={!store.cmsCompany[0].is_registration_passed}
              checked={hasAccessToCompliance}
              style={{ marginRight: 10 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => { setHasAccessToCompliance(checked) }}
            />
            {hasAccessToCompliance ? 'Active for ComplianceSource' : 'Not Active for ComplianceSource'}
          </SwitchWrap>
          <Input
            smallLabel
            defaultValue={'member'}
            name={'member_type'}
            label={'Select Role'}
            type={'select'}
            options={complianceMembersTypes}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select'}
            disabled={!hasAccessToCompliance}
          />
        </div>
        <div style={{ width: '100%', marginBottom: 65 }}>
          <SwitchWrap>
            <StyledSwitch
              disabled={!store.cmsCompany[0].have_access_to_proposed_source}
              checked={hasAccessToProposed}
              style={{ marginRight: 10 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => { setHasAccesstoProposed(checked) }}
            />
            {hasAccessToProposed ? 'Active for ProposedSource' : 'Not Active for ProposedSource'}
          </SwitchWrap>
        </div>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ width: '100%' }}>
          <SwitchWrap>
            <StyledSwitch
              disabled={!store.cmsCompany[0].have_access_to_resource}
              checked={hasAccessToResource}
              style={{ marginRight: 10 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => { setHasAccessToResource(checked) }}
            />
            {hasAccessToResource ? 'Active for ReSource' : 'Not Active for ReSource'}
          </SwitchWrap>
          <Input
            defaultValue={'member'}
            smallLabel
            name={'resource_role'}
            label={'Select Role'}
            type={'select'}
            options={resourceMembersTypes}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select'}
            disabled={!hasAccessToResource}
          />
        </div>
        <div style={{ width: '100%' }}>
          <SwitchWrap>
            <StyledSwitch
              disabled={!store.cmsCompany[0].have_access_to_report_source}
              checked={hasAccessToReport}
              style={{ marginRight: 10 }}
              checkedChildren="Yes"
              unCheckedChildren="No"
              onChange={(checked) => { setHasAccessToReport(checked) }}
            />
            {hasAccessToReport ? 'Active for ReportSource' : 'Not Active for ReportSource'}
          </SwitchWrap>
          <Input
            defaultValue={'member'}
            smallLabel
            name={'report_source_role'}
            label={'Select Role'}
            type={'select'}
            options={reportMembersTypes}
            control={control}
            validation={{ required: false }}
            transparent
            placeholder={'Select'}
            disabled={!hasAccessToReport}
          />
        </div>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          disabled={!isFormValid || disabled}
          text={edit ? 'Edit User' : 'Add User'}
          htmlType="submit"
          type={'main'}
          style={{
            width: '15%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
      {!loading &&
        <StyledTable
          columns={columns}
          dataSource={store?.cmsCompanyUsers?.results}
          rowKey={'id'}
          size='small'
          onChange={(pagination, _, sorter) => {
            setPage(pagination.current)
          }}
          pagination={{
            hideOnSinglePage: true,
            pageSize: 10,
            position: ['bottomLeft'],
            total: store?.cmsCompanyUsers?.count,
            current: page,
            showSizeChanger: false,
          }}
        />}
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <PrevBtn onClick={() => history.goBack()}>Previous</PrevBtn>
        <Button
          text={'Finished'}
          disabled={!store.cmsCompanyUsers?.results?.length}
          onClick={() => finish()}
          type={'main'}
          style={{
            width: '15%',
            padding: 0,
            marginRight: 0,
            marginLeft: '.5em',
            marginTop: '2.3em',
          }}
        />
      </Row>
    </form>
  )
};

export default UsersTab;