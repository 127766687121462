import React from 'react'
import check from '../../../../../assets/images/check-icon.svg'
import { DescriptionGlobal } from '../../styles'
import { Title, Wrap } from './style'
import { is } from 'immutable'

const Item = ({ key, title, description, isNumbered, number }) => {
  console.log(key)
  return (
    <Wrap>
      <Title style={{ fontFamily: 'var(--regular)' }}>
        {isNumbered
          ? <span style={{marginRight: 15}}>{number}.</span>
          : <img src={check} alt='check-icon' />
        }
        {title}
      </Title>
      <DescriptionGlobal>{description}</DescriptionGlobal>
    </Wrap>
  )
}

export default Item
