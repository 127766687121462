import { message } from 'antd'
import 'antd/dist/antd.css'
import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import {
  Redirect,
  Route,
  Router,
  Switch,
  useHistory,
  useLocation,
} from 'react-router-dom'
import Cookies from 'universal-cookie'
import { api } from './api'
import FullScreenLoader from './components/common/FullScreenLoader'
import TopLine from './components/TopLine/topLine'
import './css/global.css'
import {
  checkUrlAndWriteToLS,
  getReadStatuses,
  verifyToken,
} from './helpers/helpers'
import { useStore } from './mobx-store/context'
import NotFound from './pages/404'
import TimeOutModal from './components/common/SessionTimeout/TimeoutModal'
import NoSubscription from './pages/NoSubscription/NoSubscription'
import AboutPage from './pages/About'
import AddMember from './pages/Auth/AddMember/addMember'
import Billing from './pages/Auth/Billing/billing'
import BusinessInfo from './pages/Auth/BusinessInfo/businessInfo'
import ManageProducts from './pages/AdminPanel/CreateCompany/pages/ManageProducts'
import ConfirmEmail from './pages/Auth/ConfirmEmail'
import Pricing from './pages/Auth/Pricing/pricing'
import Register from './pages/Auth/Register/register'
import Step1 from './pages/Auth/ResetPass/step1'
import Step2 from './pages/Auth/ResetPass/step2'
import Step3 from './pages/Auth/ResetPass/step3'
import StartDate from './pages/Auth/StartDate/startDate'
import TopicAndJur from './pages/Auth/TopicsAndJur/topic'
import Dashboard from './pages/Dashboard/dashboard'
import EmailConfirmForGA from './pages/EmailConfirm'
import HideMeAnalytics from './pages/HideMeAnalytics'
import HomeCompliance from './pages/Home/Compliance/home'
import Home from './pages/Home/Main/index'
import HomeResource from './pages/Home/Resource/index'
import ResourceGuidesLanding from './pages/Home/ResourceGuidesLanding'
import HomeService from './pages/Home/ReportSource/index'
import AboutUs from './pages/Home/AboutUs/index'
import PayForm from './pages/Payments/payForm'
import ProposedLegislation from './pages/PendingBills/proposedPage'
import AddTeamMemberProfile from './pages/Profile/pages/AddTeamMember'
import BillingInfo from './pages/Profile/pages/Billing'
import BusinessInfoProfile from './pages/Profile/pages/BusinessInfo'
import CurrentPlan from './pages/Profile/pages/CurrentPlan'
import Restrictions from './pages/Profile/pages/Restrictions'
import AutoAssignments from './pages/Profile/pages/AutoAssignments'
import EditProfile from './pages/Profile/pages/EditProfile'
import EditTeamMemberProfile from './pages/Profile/pages/EditTeamMember'
import EmailSettings from './pages/Profile/pages/Email'
import ManageDictionaries from './pages/Profile/pages/ManageDictionaries/index'
import ManageTeamMembers from './pages/Profile/pages/ManageTeam'
import PasswordAndSecurity from './pages/Profile/pages/PasswordAndSecurity'
import ProductSettings from './pages/Profile/pages/ProductSettings'
import ResourceRoot from './pages/ResourceMain/index'
import PricingResource from './pages/ResourceMain/Register/Main'
import TermsAndPrivacy from './pages/Terms'
import TrialEnd from './pages/Tracking/NotPaid/trialOverAdmin'
import Tracking from './pages/Tracking/tracking'
import DashboardLanding from './pages/AdminPanel/DashboardLanding'
import { ServiceSelection } from './pages/ServiceSelection'
import { noEmailConfirm, notAuth, notShowMenu, dashboardMenu } from './urls'
import ValidateAccount from './pages/Auth/ValidateAccount'
import FirstPage from './pages/AdminPanel/CreateCompany/pages/FirstPage'
import EditCMSCompany from './pages/AdminPanel/EditCompany'
import EditCMSUser from './pages/AdminPanel/EditUser'
import GuidesTable from './pages/AdminPanel/ResourceEdit/GuidesTable'
import AddMultipleUsers from './pages/AdminPanel/EditCompany/AddMultipleUsers'
import AddGuideNamePage from './pages/AdminPanel/ResourceEdit/AddGuideNamePage'
import UploadGuidePage from './pages/AdminPanel/ResourceEdit/UploadGuidePage'
import ReportPage from './pages/AdminPanel/ReportEdit/ReportPage'
import CompliancePage from './pages/AdminPanel/ComplianceEdit'
import ProposedPage from './pages/AdminPanel/ProposedEdit'
import CMSActivity from './pages/AdminPanel/CMSActivity'
import DictionariesTable from './pages/AdminPanel/Dictionaries/Table'
import DashboardAllUsers from './pages/AdminPanel/UsersTable'
import AdminStaffPage from './pages/AdminPanel/AdminStaffPage'
import AddAdminStaffPage from './pages/AdminPanel/AdminStaffPage/AddUser'
import SecurityPrivacy from './pages/SecurityPrivacy'

const cookies = new Cookies()

const routes = [
  {
    key: 2,
    auth: false,
    path: '/register',
    render: () => <Register />,
    exact: false,
  },
  {
    key: 3,
    auth: false,
    path: '/login',
    render: () => <Register login />,
    exact: false,
  },
  {
    key: 4,
    auth: false,
    path: '/businessInfo/:id?',
    disableWhenRegisterPassed: true,
    render: () => <BusinessInfo />,
    exact: false,
  },
  {
    key: 5,
    auth: false,
    path: '/addMember/:type(resource|main)?',
    // disableWhenRegisterPassed: true,
    render: () => <AddMember />,
    exact: false,
  },
  {
    key: 6,
    auth: false,
    path: '/addReportMember/:type(resource|main)?',
    // disableWhenRegisterPassed: true,
    render: () => <AddMember isReportSource />,
    exact: false,
  },
  {
    key: 7,
    auth: false,
    path: '/addProposedMember/:type(resource|main)?',
    // disableWhenRegisterPassed: true,
    render: () => <AddMember isProposedSource />,
    exact: false,
  },
  {
    key: 8,
    auth: false,
    path: '/pricing',
    disableWhenRegisterPassed: true,
    render: () => <Pricing />,
    exact: false,
  },
  {
    key: 9,
    auth: false,
    path: '/topicsAndJur',
    disableWhenRegisterPassed: true,
    render: () => <TopicAndJur />,
    exact: false,
  },
  {
    key: 10,
    auth: false, // todo
    path: '/select-pay-method',
    render: () => <PayForm />,
    exact: false,
  },
  {
    key: 11,
    auth: false,
    path: '/tracking/:tabName?/:id?/:subTab?',
    render: () => <Tracking serviceName={'compliance'} />,
    exact: false,
  },
  {
    key: 12,
    auth: false,
    path: '/report-source/:tabName?/:id?/:subTab?',
    render: () => <Tracking serviceName={'report'} />,
    exact: false,
  },
  {
    key: 88,
    auth: false,
    path: '/license-source/:tabName?/:id?/:subTab?',
    render: () => <Tracking serviceName={'license'} />,
    exact: false,
  },
  {
    key: 13,
    auth: false,
    path: '/billing/:type(tracking|resources)?',
    render: () => <Billing />,
    exact: false,
  },
  {
    key: 14,
    auth: false,
    path: '/report-source-billing/:type(tracking|resources)?',
    render: () => <Billing isReportSource />,
    exact: false,
  },
  {
    key: 15,
    auth: false,
    path: '/proposed-source-billing/:type(tracking|resources)?',
    render: () => <Billing isProposedSource />,
    exact: false,
  },
  {
    key: 16,
    auth: false,
    path: '/startDate',
    disableWhenRegisterPassed: true,
    render: () => <StartDate />,
    exact: false,
  },
  {
    key: 17,
    auth: true,
    path: '/dashboard',
    render: () => <Dashboard />,
    exact: false,
  },
  {
    key: 18,
    auth: false,
    path: '/reset-password-enter-email',
    render: () => <Step1 />,
    exact: false,
  },
  {
    key: 19,
    auth: false,
    path: '/reset-password-email',
    render: () => <Step2 />,
    exact: false,
  },
  {
    key: 20,
    auth: false,
    path: '/reset-password-confirm',
    render: () => <Step3 />,
    exact: false,
  },
  {
    key: 21,
    auth: true,
    path: '/edit-profile',
    render: () => <EditProfile />,
    exact: false,
  },
  {
    key: 22,
    auth: true,
    path: '/password-and-security',
    render: () => <PasswordAndSecurity />,
    exact: false,
  },
  {
    key: 23,
    auth: true,
    path: '/profile-email/:type(tracking|resource|reportSource|proposedSource|licenseSource)?',
    render: () => <EmailSettings />,
    exact: false,
  },
  {
    key: 24,
    auth: true,
    path: '/site-notifications/:type(tracking|resource|reportSource|proposedSource|licenseSource)?',
    render: () => <EmailSettings site={true} />,
    exact: false,
  },
  {
    key: 25,
    auth: true,
    path: '/product-settings/:type(tracking|resource|reportSource|proposedSource|licenseSource)?',
    render: () => <ProductSettings />,
    exact: false,
  },
  {
    key: 26,
    auth: true,
    path: '/current-plan/:type(tracking|resource|reportSource|proposedSource|licenseSource)?',
    render: () => <CurrentPlan />,
    exact: false,
  },
  {
    key: 27,
    auth: true,
    path: '/restrictions/:type(tracking|resource|reportSource|proposedSource|licenseSource)?',
    render: () => <Restrictions />,
    exact: false,
  },
  {
    key: 28,
    auth: true,
    path: '/auto-assignments/:type(tracking|resource|reportSource|proposedSource|licenseSource)?',
    render: () => <AutoAssignments />,
    exact: false,
  },
  {
    key: 29,
    auth: true,
    path: '/business-info',
    render: () => <BusinessInfoProfile />,
    exact: false,
  },
  {
    key: 30,
    auth: true,
    path: '/manage-team-members',
    render: () => <ManageTeamMembers />,
    exact: false,
  },
  {
    key: 31,
    auth: true,
    path: '/add-team-members-profile/:type(tracking|resource|reportSource|proposedSource||licenseSource)?',
    render: () => <AddTeamMemberProfile />,
    exact: false,
  },
  {
    key: 32,
    auth: true,
    path: '/edit-team-members-profile/:id/:type(tracking|resource|reportSource|proposedSource||licenseSource)?',
    render: () => <EditTeamMemberProfile />,
    exact: false,
  },
  {
    key: 33,
    auth: true,
    path: '/billing-info',
    render: () => <BillingInfo />,
    exact: false,
  },
  {
    key: 34,
    path: '/home',
    render: () => <Home />,
  },
  {
    key: 35,
    path: '/home-compliance',
    render: () => <HomeCompliance />,
  },
  {
    key: 36,
    path: '/home-resource',
    render: () => <HomeResource />,
  },
  {
    key: 37,
    path: '/home-report-source',
    render: () => <HomeService service={'report'} />,
  },
  {
    key: 371,
    path: '/home-license-source',
    render: () => <HomeService service={'license'} />,
  },
  {
    key: 38,
    path: '/home-proposed-source',
    render: () => <HomeService service={'proposed'} />,
  },
  {
    key: 39,
    path: '/about-us',
    render: () => <AboutUs />,
  },
  {
    key: 40,
    path: '/terms-and-privacy',
    render: () => <TermsAndPrivacy />,
  },
  {
    key: 91,
    path: '/secuity-and-privacy',
    render: () => <SecurityPrivacy />,
  },
  {
    key: 41,
    auth: true,
    path: '/resource',
    render: () => <ResourceRoot />,
  },
  {
    key: 42,
    auth: false,
    path: '/confirm-email',
    render: () => <ConfirmEmail />,
  },
  {
    key: 43,
    auth: false,
    path: '/validate-account',
    render: () => <ValidateAccount />,
  },
  {
    key: 43,
    auth: false,
    path: '/otp-validation',
    render: () => <ValidateAccount otpVaidation={true} />,
  },
  {
    key: 44,
    auth: false,
    path: '/resource-pricing',
    render: () => <PricingResource />,
  },
  {
    key: 45,
    path: '/about',
    render: () => <AboutPage />,
  },
  {
    key: 46,
    path: '/email-confirmed',
    render: () => <EmailConfirmForGA />,
  },
  {
    key: 47,
    path: '/analytic/hide-me',
    render: () => <HideMeAnalytics />,
  },
  {
    key: 48,
    path: '/company_error/:type',
    render: () => <TrialEnd />,
  },
  {
    key: 49,
    path: '/proposed-source',
    render: () => <ProposedLegislation />,
  },
  {
    key: 50,
    path: '/manage-dictionaries/:type(topics|sub_topics|lines_of_business|jurisdiction|report_types|reporting_entities)',
    render: () => <ManageDictionaries />,
  },
  {
    key: 51,
    path: '/no-resource-subscription/',
    render: () => <NoSubscription serviceName={'ReSource'} />,
  },
  {
    key: 52,
    path: '/no-compliance-source-subscription/',
    render: () => <NoSubscription serviceName={'ComplianceSource'} />,
  },
  {
    key: 53,
    path: '/no-report-source-subscription/',
    render: () => <NoSubscription serviceName={'ReportSource'} />,
  },
  {
    key: 541,
    path: '/no-license-source-subscription/',
    render: () => <NoSubscription serviceName={'LicenseSource'} />,
  },
  {
    key: 54,
    path: '/no-proposed-source-subscription/',
    render: () => <NoSubscription serviceName={'ProposedSource'} />,
  },
  {
    key: 55,
    path: '/service-selection/',
    render: () => <ServiceSelection />,
  },
  {
    key: 56,
    path: '/dashboard-landing/',
    cmsAuth: true,
    render: () => <DashboardLanding />,
  },
  {
    key: 57,
    cmsAuth: true,
    path: '/dashboard-edit-company/:id/',
    render: () => <EditCMSCompany />,
  },
  {
    key: 58,
    cmsAuth: true,
    path: '/dashboard-edit-user/:id/',
    render: () => <EditCMSUser />,
  },
  {
    key: 59,
    cmsAuth: true,
    path: '/dashboard-guides-table/',
    render: () => <GuidesTable tableType={'guides'} />,
  },
  {
    key: 60,
    cmsAuth: true,
    path: '/dashboard-guides-names/',
    render: () => <GuidesTable tableType={'guideNames'} />,
  },
  {
    key: 61,
    cmsAuth: true,
    path: '/dashboard-report-table/',
    render: () => <GuidesTable tableType={'report'} />,
  },
  {
    key: 62,
    cmsAuth: true,
    path: '/dashboard-compliance-table/',
    render: () => <GuidesTable tableType={'compliance'} />,
  },
  {
    key: 62,
    cmsAuth: true,
    path: '/dashboard-proposed-table',
    render: () => <GuidesTable tableType={'proposed'} />,
  },
  {
    key: 61,
    cmsAuth: true,
    path: '/dashboard-add-guide-name/',
    render: () => <AddGuideNamePage />,
  },
  {
    key: 62,
    cmsAuth: true,
    path: '/dashboard-upload-guide/',
    render: () => <UploadGuidePage />,
  },
  {
    key: 63,
    cmsAuth: true,
    path: '/create-new-company',
    render: () => <FirstPage />,
  },
  {
    key: 64,
    cmsAuth: true,
    path: '/manage-products',
    render: () => <ManageProducts />,
  },
  {
    key: 65,
    cmsAuth: true,
    path: '/add-multiple-users/:id',
    render: () => <AddMultipleUsers />,
  },
  {
    key: 66,
    path: '/resource-guides-landing/:id?',
    render: () => <ResourceGuidesLanding />,
  },
  {
    key: 67,
    cmsAuth: true,
    path: '/dashboard-add-report',
    render: () => <ReportPage editMode={false} />,
  },
  {
    key: 68,
    cmsAuth: true,
    path: '/dashboard-edit-report/:id?',
    render: () => <ReportPage editMode={true} />,
  },
  {
    key: 67,
    cmsAuth: true,
    path: '/dashboard-add-compliance',
    render: () => <CompliancePage editMode={false} />,
  },
  {
    key: 68,
    cmsAuth: true,
    path: '/dashboard-edit-compliance/:id?',
    render: () => <CompliancePage editMode={true} />,
  },
  {
    key: 69,
    cmsAuth: true,
    path: '/dashboard-compliance-activity/:id?',
    render: () => <CMSActivity serviceName={'compliance'} />,
  },
  {
    key: 70,
    cmsAuth: true,
    path: '/dashboard-report-activity/:id?',
    render: () => <CMSActivity serviceName={'report'} />,
  },
  {
    key: 71,
    cmsAuth: true,
    path: '/dashboard-proposed-activity/:id?',
    render: () => <CMSActivity serviceName={'proposed'} />,
  },
  {
    key: 72,
    cmsAuth: true,
    path: '/dashboard-add-proposed',
    render: () => <ProposedPage editMode={false} />,
  },
  {
    key: 73,
    cmsAuth: true,
    path: '/dashboard-edit-proposed/:id?',
    render: () => <ProposedPage editMode={true} />,
  },
  {
    key: 74,
    cmsAuth: true,
    path: '/dashboard-dictionaries-jurisdictions',
    render: () => < DictionariesTable title={'Jurisdictions'} name={'jurisdictions'} btnName={'Jurisdiction'} />,
  },
  {
    key: 75,
    cmsAuth: true,
    path: '/dashboard-dictionaries-lines-of-business',
    render: () => < DictionariesTable name={'lines_of_business'} title={'Lines of Business'} btnName={'Lines of Business'} />,
  },
  {
    key: 76,
    cmsAuth: true,
    path: '/dashboard-dictionaries-topics',
    render: () => < DictionariesTable name={'topics'} title={'Topics'} btnName={'Topic'} />,
  },
  {
    key: 77,
    cmsAuth: true,
    path: '/dashboard-dictionaries-sub-topics',
    render: () => < DictionariesTable name={'sub_topics'} title={'Sub-Topics'} btnName={'Sub-Topic'} />,
  },
  {
    key: 78,
    cmsAuth: true,
    path: '/dashboard-dictionaries-surveys',
    render: () => < DictionariesTable name={'survey'} title={'Surveys'} btnName={'Survey'} />,
  },
  {
    key: 79,
    cmsAuth: true,
    path: '/dashboard-dictionaries-reference-types',
    render: () => < DictionariesTable name={'reference_types'} title={'Reference Types'} btnName={'Reference Type'} />,
  },
  {
    key: 80,
    cmsAuth: true,
    path: '/dashboard-dictionaries-reporting-entities',
    render: () => < DictionariesTable name={'reporting_entity'} title={'Reporting Entities'} btnName={'Reporting Entity'} />,
  },
  {
    key: 81,
    cmsAuth: true,
    path: '/dashboard-dictionaries-reporting-types',
    render: () => < DictionariesTable name={'report_types'} title={'Reporting Types'} btnName={'Reporting Type'} />,
  },
  {
    key: 82,
    cmsAuth: true,
    path: '/dashboard-dictionaries-ties-to-license',
    render: () => < DictionariesTable name={'ties_to_license'} title={'Ties to License'} btnName={'Ties to License'} />,
  },
  {
    key: 83,
    cmsAuth: true,
    path: '/dashboard-dictionaries-applies-to',
    render: () => < DictionariesTable name={'applies_to'} title={'Applies To'} btnName={'Applies To'} />,
  },
  {
    key: 84,
    cmsAuth: true,
    path: '/dashboard-all-users',
    render: () => <DashboardAllUsers allUsersPage={'all'} />,
  },
  {
    key: 85,
    cmsAuth: true,
    path: '/dashboard-settings',
    render: () => <AdminStaffPage allUsersPage={'all'} />,
  },
  {
    key: 86,
    cmsAuth: true,
    path: '/dashboard-add-admin',
    render: () => <AddAdminStaffPage isEditMode={false} />,
  },
  {
    key: 87,
    cmsAuth: true,
    path: '/dashboard-edit-admin/:id?',
    render: () => <AddAdminStaffPage isEditMode={true} />,
  }
]

const App = observer(() => {
  const { pathname, search } = useLocation()
  const store = useStore()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [isAuthenticated, setIsAuthenticated] = useState(!!cookies.get('pbm_token'));
  const [visibleTimeout, setVisibleTimeout] = useState(false)

  const handleOnActive = () => {
    localStorage.setItem('lastActiveTime', new Date().toISOString());
  };



  useEffect(() => {
    const showModal = () => setVisibleTimeout(true);
    const hideModal = () => setVisibleTimeout(false);

    const handleStorageChange = (event) => {
      if (event.key === 'continueClicked' && event.newValue === 'true') {
        setTimeout(() => { localStorage.removeItem('continueClicked') }, 4000)
        hideModal();
      } else if (event.key === 'logoutClicked' && event.newValue === 'true') {
        localStorage.removeItem('logoutClicked');
        hideModal();
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  useEffect(() => {
    checkUrlAndWriteToLS(pathname, search)
  }, [])

  useEffect(() => {
    history.listen((newLocation) => {
      if (newLocation.pathname === '/reset-password-confirm/') {
        history.go(1)
      }
    })
  }, [])

  useEffect(() => {
    if (pathname === '/login'
      || pathname === '/validate-account'
      || pathname === '/home'
      || pathname === '/otp-validation'
    ) {
      setIsAuthenticated(false);
    } else {
      setIsAuthenticated(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      !noEmailConfirm.includes('/' + pathname.split('/')[1]) &&
      store.user.length &&
      !store.user[0]?.is_confirmed
    ) {
      history.push('/confirm-email')
    }
  }, [store.user, pathname])

  useEffect(() => {
    message.destroy()
  }, [pathname])

  const getCompanyId = () => api('/companies/', {}, 'GET')

  message.config({
    duration: 10,
  })

  useEffect(() => {
    if (cookies.get('pbm_token') && !store.company.length) {
      getCompanyId().then((data) => {
        if (data.errors) {
          cookies.remove('pbm_token')
          setLoading(false)
          store.reset()
          history.push('/home')
        } else {
          getReadStatuses('/legislative/read_for_current_user/', 'readItems');
          getReadStatuses('/report_company/read_for_current_user/', 'readReportItems');
          getReadStatuses('/license_company/read_for_current_user/', 'readLicenseItems');
          store.getUser()
          store.getTariffInfo()
          store.setSingleValue('company', data)
          store.setSingleValue(
            'maxUsers',
            data.tariff === 'total_tract' ? 10 : 5
          )
          store.loadSelect('companyOptions', () =>
            store.getSelectedJurisdictions()
          )
          setLoading(false)
          if (history.location.pathname === '/') {
            if (
              data[0].started_from_resource &&
              !data[0].is_registration_passed
            ) {
              return history.push('/resource')
            }
            history.push('/tracking')
          }
        }
      })
    } else {
      if (!notAuth.includes('/' + pathname.split('/')[1])) {
        history.push('/home')
      }
      cookies.remove('pbm_token')
      setLoading(false)
      store.reset()
    }
  }, [])

  const onPresenceChange = (presence) => {
    // Handle state changes in one function
  }

  const onPrompt = () => {
    setVisibleTimeout(true)
  }

  const onIdle = () => {
    localStorage.setItem(
      'lastSessionPathname',
      pathname
    )
    history.push('/login');
    cookies.remove('pbm_token');
    store.reset();
    setVisibleTimeout(false)
    setIsAuthenticated(false)
  }

  const {
    reset,
  } = useIdleTimer({
    onPresenceChange,
    onPrompt,
    onIdle,
    onActive: handleOnActive,
    onAction: handleOnActive,
    timeout: 1000 * 60 * 180,
    promptBeforeIdle: 1000 * 60 * 15,
    disabled: !isAuthenticated,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ],
    immediateEvents: [],
    debounce: 0,
    throttle: 0,
    eventsThrottle: 200,
    element: document,
    startOnMount: true,
    startManually: false,
    stopOnIdle: false,
    crossTab: true,
    name: 'idle-timer',
    syncTimers: 0,
    leaderElection: false
  })

  useEffect(() => {
    const handleStorageEvent = (event) => {
      if (event.key === 'lastActiveTime') {
        reset();
      }
    };

    window.addEventListener('storage', handleStorageEvent);

    return () => {
      window.removeEventListener('storage', handleStorageEvent);
    };
  }, [reset]);

  const onContinue = () => {
    setVisibleTimeout(false);
    reset();
  }
  if (loading) return <FullScreenLoader />



  const pages = (
    <Switch>
      {routes.map(({ key, auth, cmsAuth, path, exact, render, disableWhenRegisterPassed }) => {
        if (cmsAuth) {
          return <CMSAuthRoute path={path} render={render} exact={exact} key={key} store={store} />;
        } else if (auth) {
          return <AuthRoute path={path} render={render} exact={exact} key={key} store={store} />;
        } else {
          return <CustomRoute path={path} render={render} exact={exact} key={key} store={store} disableWhenRegisterPassed={disableWhenRegisterPassed} />;
        }
      })}
      <Route component={NotFound} />
    </Switch>
  )

  return (
    <Router history={history} >
      {visibleTimeout && <TimeOutModal
        onLogout={onIdle}
        onContinue={onContinue}
      />}
      {notShowMenu.includes('/' + pathname.split('/')[1]) ? (
        pages
      ) : dashboardMenu.includes('/' + pathname.split('/')[1]) ?
        (<>
          <TopLine adminPanel={true} />
          {pages}
        </>)
        : (
          <>
            <TopLine />
            {pages}
          </>
        )}
    </Router>
  )
})

export default App

const CustomRoute = observer(
  ({ path, exact, render, disableWhenRegisterPassed, store }) => {
    if (disableWhenRegisterPassed && store.company[0]?.is_registration_passed) {
      cookies.remove('pbm_token')
      store.reset()
      return <Redirect to={'/login'} />
    }
    if (!dashboardMenu.includes('/' + path.split('/')[1])
      && !notAuth.includes('/' + path.split('/')[1])
      && localStorage.getItem('accountType') !== 'regular_user'
    ) {
      return <Redirect to={'/dashboard-landing'} />
    }

    return <Route path={path} render={render} exact={exact} />
  }
)

const redirectToWarning = (store) => (
  <Redirect
    to={`/company_error/${store.company[0].payment_status === 'trial_is_over'
      ? store.company[0].payment_status
      : 'payment_' + store.company[0].payment_status
      }`}
  />
)

const checkIfShouldRedirect = (store) => {
  if (
    ['trial_is_over', 'error', 'cancelled'].includes(
      store.company?.[0]?.payment_status
    ) &&
    store.user?.[0]?.member_type !== 'admin'
  )
    return (
      <Redirect
        to={`/company_error/${store.company[0].payment_status === 'trial_is_over'
          ? store.company[0].payment_status
          : 'payment_' + store.company[0].payment_status
          }`}
      />
    )
}

const AuthRoute = ({ path, exact, render, store }) => {
  if (!verifyToken()) return <Redirect to={'/home'} />
  if (checkIfShouldRedirect(store)) return redirectToWarning(store)
  if (localStorage.getItem('accountType') !== 'regular_user') return <Redirect to={'/dashboard-landing'} />
  return <Route path={path} exact={exact} render={render} />
}

const CMSAuthRoute = ({ path, exact, render, store }) => {
  if (!verifyToken()) return <Redirect to={'/login'} />;
  if (checkIfShouldRedirect(store)) return redirectToWarning(store);
  if (localStorage.getItem('accountType') === 'regular_user') return <Redirect to={'/login'} />
  return <Route path={path} exact={exact} render={render} />;
}